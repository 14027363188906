@import "./lib/org.scss";

.org-src-container {
    color: #b2b2b2;
    background-color: #262626;
}
.org-ahs {
    /* ahs-face */
    background-color: #444444;
}
.org-ahs-definition {
    /* ahs-definition-face */
    color: #ffe4b5;
    background-color: #5f9ea0;
    text-decoration: underline;
}
.org-ahs-edit-mode {
    /* ahs-edit-mode-face */
    color: #ffffff;
    background-color: #cd5b45;
}
.org-ahs-plugin-bod {
    /* ahs-plugin-bod-face */
    color: #000000;
    background-color: #1e90ff;
}
.org-ahs-plugin-defalt {
    /* ahs-plugin-defalt-face */
    color: #000000;
    background-color: #ffa500;
}
.org-ahs-plugin-whole-buffer {
    /* ahs-plugin-whole-buffer-face */
    color: #262626;
    background-color: #86dc2f;
}
.org-ahs-warning {
    /* ahs-warning-face */
    color: #ff0000;
    font-weight: bold;
}
.org-anzu-match-1 {
    /* anzu-match-1 */
    color: #000000;
    background-color: #32cd32;
}
.org-anzu-match-2 {
    /* anzu-match-2 */
    color: #000000;
    background-color: #ffff00;
}
.org-anzu-match-3 {
    /* anzu-match-3 */
    color: #000000;
    background-color: #7fffd4;
}
.org-anzu-mode-line {
    /* anzu-mode-line */
    color: #875f00;
    font-weight: bold;
}
.org-anzu-mode-line-no-match {
    /* anzu-mode-line-no-match */
    color: #875f00;
    font-weight: bold;
}
.org-anzu-replace-highlight {
    /* anzu-replace-highlight */
    color: #262626;
    background-color: #86dc2f;
}
.org-anzu-replace-to {
    /* anzu-replace-to */
    color: #ffff00;
}
.org-apropos-keybinding {
    /* apropos-keybinding */
    text-decoration: underline;
}
.org-apropos-property {
    /* apropos-property */
    color: #268bd2;
}
.org-apropos-symbol {
    /* apropos-symbol */
    font-weight: bold;
}
.org-bold {
    /* bold */
    font-weight: bold;
}
.org-bold-italic {
    /* bold-italic */
    font-weight: bold;
    font-style: italic;
}
.org-bookmark-menu-bookmark {
    /* bookmark-menu-bookmark */
    font-weight: bold;
}
.org-bookmark-menu-heading {
    /* bookmark-menu-heading */
    color: #df005f;
    font-weight: bold;
}
.org-border {
}
.org-buffer-menu-buffer {
    /* buffer-menu-buffer */
    font-weight: bold;
}
.org-builtin {
    /* font-lock-builtin-face */
    color: #268bd2;
}
.org-button {
    /* button */
    color: #008787;
    text-decoration: underline;
}
.org-c-annotation {
    /* c-annotation-face */
    color: #d75fd7;
}
.org-calendar-month-header {
    /* calendar-month-header */
    color: #d75fd7;
    font-weight: bold;
}
.org-calendar-today {
    /* calendar-today */
    text-decoration: underline;
}
.org-calendar-weekday-header {
    /* calendar-weekday-header */
    color: #d75fd7;
}
.org-calendar-weekend-header {
    /* calendar-weekend-header */
    color: #008787;
    background-color: #262626;
}
.org-change-log-acknowledgment {
    /* change-log-acknowledgment */
    color: #008787;
    background-color: #262626;
}
.org-change-log-conditionals {
    /* change-log-conditionals */
    color: #8787d7;
}
.org-change-log-date {
    /* change-log-date */
    color: #2aa198;
}
.org-change-log-email {
    /* change-log-email */
    color: #8787d7;
}
.org-change-log-file {
    /* change-log-file */
    color: #d75fd7;
    font-weight: bold;
}
.org-change-log-function {
    /* change-log-function */
    color: #8787d7;
}
.org-change-log-list {
    /* change-log-list */
    color: #268bd2;
    font-weight: bold;
}
.org-change-log-name {
    /* change-log-name */
    color: #d75fd7;
}
.org-comint-highlight-input {
    /* comint-highlight-input */
    font-weight: bold;
}
.org-comint-highlight-prompt {
    /* comint-highlight-prompt */
    color: #268bd2;
    font-weight: bold;
}
.org-comment {
    /* font-lock-comment-face */
    color: #008787;
    background-color: #262626;
}
.org-comment-delimiter {
    /* font-lock-comment-delimiter-face */
    color: #008787;
    background-color: #262626;
}
.org-company-echo {
}
.org-company-echo-common {
    /* company-echo-common */
    color: #262626;
    background-color: #b2b2b2;
}
.org-company-preview {
    /* company-preview */
    color: #888888;
    background-color: #444444;
}
.org-company-preview-common {
    /* company-preview-common */
    color: #b2b2b2;
    background-color: #444444;
}
.org-company-preview-search {
    /* company-preview-search */
    color: #86dc2f;
    background-color: #444444;
}
.org-company-scrollbar-bg {
    /* company-scrollbar-bg */
    background-color: #1c1c1c;
}
.org-company-scrollbar-fg {
    /* company-scrollbar-fg */
    background-color: #444444;
}
.org-company-template-field {
    /* company-template-field */
    background-color: #444444;
}
.org-company-tooltip {
    /* company-tooltip */
    color: #888888;
    background-color: #444444;
}
.org-company-tooltip-annotation {
    /* company-tooltip-annotation */
    color: #df005f;
}
.org-company-tooltip-annotation-selection {
    /* company-tooltip-annotation-selection */
    color: #df005f;
}
.org-company-tooltip-common {
    /* company-tooltip-common */
    color: #268bd2;
    background-color: #444444;
}
.org-company-tooltip-common-selection {
    /* company-tooltip-common-selection */
    color: #b2b2b2;
}
.org-company-tooltip-mouse {
    /* company-tooltip-mouse */
    color: #b2b2b2;
    background-color: #444444;
}
.org-company-tooltip-search {
    /* company-tooltip-search */
    color: #86dc2f;
    background-color: #444444;
}
.org-company-tooltip-search-selection {
    /* company-tooltip-search-selection */
    color: #b2b2b2;
    background-color: #444444;
}
.org-company-tooltip-selection {
    /* company-tooltip-selection */
    color: #b2b2b2;
    background-color: #333333;
}
.org-company-web-doc-attribute {
    /* company-web-doc-attribute-face */
    color: #df005f;
    font-weight: bold;
}
.org-company-web-doc-base {
    /* company-web-doc-base-face */
    color: #00bfff;
}
.org-company-web-doc-header-1 {
    /* company-web-doc-header-1-face */
    color: #00bfff;
    font-weight: bold;
}
.org-company-web-doc-important {
    /* company-web-doc-important-face */
    color: #00bfff;
    font-weight: bold;
}
.org-company-web-doc-tag {
    /* company-web-doc-tag-face */
    color: #d75fd7;
    font-weight: bold;
}
.org-company-web-doc-text-1 {
    /* company-web-doc-text-1-face */
    color: #00bfff;
}
.org-company-web-doc-warning {
    /* company-web-doc-warning-face */
    color: #dc752f;
    background-color: #262626;
}
.org-compilation-column-number {
    /* compilation-column-number */
    color: #af875f;
}
.org-compilation-error {
    /* compilation-error */
    color: #e0211d;
}
.org-compilation-info {
    /* compilation-info */
    color: #86dc2f;
}
.org-compilation-line-number {
    /* compilation-line-number */
    color: #268bd2;
    font-weight: bold;
}
.org-compilation-mode-line-exit {
    /* compilation-mode-line-exit */
    color: #228b22;
    font-weight: bold;
}
.org-compilation-mode-line-fail {
    /* compilation-mode-line-fail */
    color: #ff0000;
    font-weight: bold;
}
.org-compilation-mode-line-run {
    /* compilation-mode-line-run */
    color: #dc752f;
}
.org-compilation-warning {
    /* compilation-warning */
    color: #dc752f;
}
.org-completions-annotations {
    /* completions-annotations */
    text-decoration: underline;
}
.org-completions-common-part {
}
.org-completions-first-difference {
    /* completions-first-difference */
    font-weight: bold;
}
.org-constant {
    /* font-lock-constant-face */
    color: #d75fd7;
}
.org-counsel-variable-documentation {
    /* counsel-variable-documentation */
    color: #008787;
    background-color: #262626;
}
.org-css-property {
    /* css-property */
    color: #268bd2;
    font-weight: bold;
}
.org-css-selector {
    /* css-selector */
    color: #d75fd7;
    font-weight: bold;
}
.org-cursor {
    /* cursor */
    background-color: #d0d0d0;
}
.org-custom-button {
    /* custom-button */
    color: #b2b2b2;
    background-color: #1c1c1c;
}
.org-custom-button-mouse {
}
.org-custom-button-pressed {
}
.org-custom-button-pressed-unraised {
    /* custom-button-pressed-unraised */
    color: #ee82ee;
    text-decoration: underline;
}
.org-custom-button-unraised {
    /* custom-button-unraised */
    text-decoration: underline;
}
.org-custom-changed {
    /* custom-changed */
    color: #ffffff;
    background-color: #0000ff;
}
.org-custom-comment {
    /* custom-comment */
    color: #000000;
    background-color: #cdcd00;
}
.org-custom-comment-tag {
    /* custom-comment-tag */
    color: #cccccc;
}
.org-custom-documentation {
}
.org-custom-face-tag {
    /* custom-face-tag */
    color: #add8e6;
    font-weight: bold;
}
.org-custom-group-subtitle {
    /* custom-group-subtitle */
    font-weight: bold;
}
.org-custom-group-tag {
    /* custom-group-tag */
    color: #add8e6;
    font-size: 120%;
    font-weight: bold;
}
.org-custom-group-tag-1 {
    /* custom-group-tag-1 */
    color: #ffc0cb;
    font-size: 120%;
    font-weight: bold;
}
.org-custom-invalid {
    /* custom-invalid */
    color: #ffff00;
    background-color: #ff0000;
}
.org-custom-link {
    /* custom-link */
    color: #008787;
    text-decoration: underline;
}
.org-custom-modified {
    /* custom-modified */
    color: #ffffff;
    background-color: #0000ff;
}
.org-custom-rogue {
    /* custom-rogue */
    color: #ffc0cb;
    background-color: #000000;
}
.org-custom-saved {
    /* custom-saved */
    text-decoration: underline;
}
.org-custom-set {
    /* custom-set */
    color: #0000ff;
    background-color: #ffffff;
}
.org-custom-state {
    /* custom-state */
    color: #32cd32;
}
.org-custom-themed {
    /* custom-themed */
    color: #ffffff;
    background-color: #0000ff;
}
.org-custom-variable-button {
    /* custom-variable-button */
    font-weight: bold;
    text-decoration: underline;
}
.org-custom-variable-tag {
    /* custom-variable-tag */
    color: #add8e6;
    font-weight: bold;
}
.org-custom-visibility {
    /* custom-visibility */
    color: #008787;
    font-size: 80%;
    text-decoration: underline;
}
.org-diary {
    /* diary */
    color: #ffff00;
}
.org-diff-added {
    /* diff-added */
    color: #67b11d;
}
.org-diff-changed {
    /* diff-changed */
    color: #268bd2;
}
.org-diff-context {
    /* diff-context */
    color: #dddddd;
}
.org-diff-file-header {
    /* diff-file-header */
    color: #b2b2b2;
    background-color: #333333;
}
.org-diff-function {
    /* diff-function */
    color: #d75fd7;
    background-color: #333333;
}
.org-diff-header {
    /* diff-header */
    color: #d75fd7;
    background-color: #333333;
}
.org-diff-hunk-header {
    /* diff-hunk-header */
    color: #d75fd7;
    background-color: #333333;
}
.org-diff-index {
    /* diff-index */
    color: #b2b2b2;
    background-color: #333333;
}
.org-diff-indicator-added {
    /* diff-indicator-added */
    color: #67b11d;
}
.org-diff-indicator-changed {
    /* diff-indicator-changed */
    color: #268bd2;
}
.org-diff-indicator-removed {
    /* diff-indicator-removed */
    color: #d70000;
}
.org-diff-nonexistent {
    /* diff-nonexistent */
    color: #b2b2b2;
    background-color: #333333;
}
.org-diff-refine-added {
    /* diff-refine-added */
    color: #262626;
    background-color: #67b11d;
}
.org-diff-refine-changed {
    /* diff-refine-changed */
    color: #262626;
    background-color: #268bd2;
}
.org-diff-refine-removed {
    /* diff-refine-removed */
    color: #262626;
    background-color: #d70000;
}
.org-diff-removed {
    /* diff-removed */
    color: #d70000;
}
.org-dired-directory {
    /* dired-directory */
    color: #268bd2;
    background-color: #262626;
    font-weight: bold;
}
.org-dired-flagged {
    /* dired-flagged */
    color: #d70000;
}
.org-dired-header {
    /* dired-header */
    color: #d75fd7;
    font-weight: bold;
}
.org-dired-ignored {
    /* dired-ignored */
    color: #585858;
}
.org-dired-mark {
    /* dired-mark */
    color: #d75fd7;
    font-weight: bold;
}
.org-dired-marked {
    /* dired-marked */
    color: #af00df;
    font-weight: bold;
}
.org-dired-perm-write {
    /* dired-perm-write */
    color: #b2b2b2;
    text-decoration: underline;
}
.org-dired-symlink {
    /* dired-symlink */
    color: #00ffff;
    background-color: #262626;
    font-weight: bold;
}
.org-dired-warning {
    /* dired-warning */
    color: #dc752f;
}
.org-doc {
    /* font-lock-doc-face */
    color: #af875f;
}
.org-eldoc-highlight-function-argument {
    /* eldoc-highlight-function-argument */
    color: #86dc2f;
    font-weight: bold;
}
.org-emmet-preview-input {
    /* emmet-preview-input */
    background-color: #121212;
}
.org-emmet-preview-output {
    /* emmet-preview-output */
    color: #b2b2b2;
    background-color: #444444;
}
.org-epa-field-body {
    /* epa-field-body */
    color: #40e0d0;
    font-style: italic;
}
.org-epa-field-name {
    /* epa-field-name */
    color: #afeeee;
    font-weight: bold;
}
.org-epa-mark {
    /* epa-mark */
    color: #ffa500;
    font-weight: bold;
}
.org-epa-string {
    /* epa-string */
    color: #ffffe0;
}
.org-epa-validity-disabled {
    /* epa-validity-disabled */
    font-style: italic;
}
.org-epa-validity-high {
    /* epa-validity-high */
    color: #afeeee;
    font-weight: bold;
}
.org-epa-validity-low {
    /* epa-validity-low */
    font-style: italic;
}
.org-epa-validity-medium {
    /* epa-validity-medium */
    color: #afeeee;
    font-style: italic;
}
.org-error {
    /* error */
    color: #e0211d;
}
.org-escape-glyph {
    /* escape-glyph */
    color: #00ffff;
}
.org-eval-sexp-fu-flash {
    /* eval-sexp-fu-flash */
    color: #262626;
    background-color: #86dc2f;
}
.org-eval-sexp-fu-flash-error {
    /* eval-sexp-fu-flash-error */
    color: #262626;
    background-color: #e0211d;
}
.org-evil-ex-commands {
    /* evil-ex-commands */
    font-style: italic;
    text-decoration: underline;
}
.org-evil-ex-info {
    /* evil-ex-info */
    color: #ff0000;
    font-style: italic;
}
.org-evil-ex-lazy-highlight {
    /* evil-ex-lazy-highlight */
    background-color: #262626;
}
.org-evil-ex-search {
    /* evil-ex-search */
    color: #262626;
    background-color: #86dc2f;
}
.org-evil-ex-substitute-matches {
    /* evil-ex-substitute-matches */
    color: #d70000;
    background-color: #262626;
}
.org-evil-ex-substitute-replacement {
    /* evil-ex-substitute-replacement */
    color: #67b11d;
    background-color: #262626;
}
.org-eww-form-checkbox {
}
.org-eww-form-file {
}
.org-eww-form-select {
}
.org-eww-form-submit {
}
.org-eww-form-text {
    /* eww-form-text */
    color: #ffffff;
    background-color: #505050;
}
.org-eww-form-textarea {
    /* eww-form-textarea */
    color: #000000;
    background-color: #C0C0C0;
}
.org-eww-invalid-certificate {
    /* eww-invalid-certificate */
    color: #ff0000;
    font-weight: bold;
}
.org-eww-valid-certificate {
    /* eww-valid-certificate */
    color: #228b22;
    font-weight: bold;
}
.org-eyebrowse-mode-line-active {
    /* eyebrowse-mode-line-active */
    font-weight: bold;
}
.org-eyebrowse-mode-line-delimiters {
}
.org-eyebrowse-mode-line-inactive {
}
.org-eyebrowse-mode-line-separator {
}
.org-ffap {
    /* ffap */
    color: #b2b2b2;
}
.org-file-name-shadow {
    /* file-name-shadow */
    color: #585858;
}
.org-fixed-pitch {
}
.org-fixed-pitch-serif {
}
.org-flx-highlight {
    /* flx-highlight-face */
    color: #d75fd7;
}
.org-flycheck-error {
    /* flycheck-error */
    text-decoration: underline;
}
.org-flycheck-error-list-checker-name {
    /* flycheck-error-list-checker-name */
    color: #268bd2;
}
.org-flycheck-error-list-column-number {
    /* flycheck-error-list-column-number */
    color: #d75fd7;
}
.org-flycheck-error-list-error {
    /* flycheck-error-list-error */
    color: #e0211d;
}
.org-flycheck-error-list-filename {
    /* flycheck-error-list-filename */
    color: #8787d7;
}
.org-flycheck-error-list-highlight {
    /* flycheck-error-list-highlight */
    color: #b2b2b2;
    background-color: #444444;
}
.org-flycheck-error-list-id {
    /* flycheck-error-list-id */
    color: #df005f;
    font-weight: bold;
}
.org-flycheck-error-list-id-with-explainer {
    /* flycheck-error-list-id-with-explainer */
    color: #df005f;
    font-weight: bold;
}
.org-flycheck-error-list-info {
    /* flycheck-error-list-info */
    color: #86dc2f;
}
.org-flycheck-error-list-line-number {
    /* flycheck-error-list-line-number */
    color: #d75fd7;
}
.org-flycheck-error-list-warning {
    /* flycheck-error-list-warning */
    color: #dc752f;
}
.org-flycheck-fringe-error {
    /* flycheck-fringe-error */
    color: #e0211d;
    font-weight: bold;
}
.org-flycheck-fringe-info {
    /* flycheck-fringe-info */
    color: #268bd2;
    font-weight: bold;
}
.org-flycheck-fringe-warning {
    /* flycheck-fringe-warning */
    color: #dc752f;
    font-weight: bold;
}
.org-flycheck-info {
    /* flycheck-info */
    text-decoration: underline;
}
.org-flycheck-verify-select-checker {
}
.org-flycheck-warning {
    /* flycheck-warning */
    text-decoration: underline;
}
.org-flymake-error {
    /* flymake-error */
    text-decoration: underline;
}
.org-flymake-note {
    /* flymake-note */
    text-decoration: underline;
}
.org-flymake-warning {
    /* flymake-warning */
    text-decoration: underline;
}
.org-flyspell-duplicate {
    /* flyspell-duplicate */
    text-decoration: underline;
}
.org-flyspell-incorrect {
    /* flyspell-incorrect */
    text-decoration: underline;
}
.org-fringe {
    /* fringe */
    color: #b2b2b2;
    background-color: #262626;
}
.org-function-name {
    /* font-lock-function-name-face */
    color: #d75fd7;
    font-weight: bold;
}
.org-git-commit-comment-action {
    /* git-commit-comment-action */
    font-weight: bold;
}
.org-git-commit-comment-branch-local {
    /* git-commit-comment-branch-local */
    color: #8787d7;
}
.org-git-commit-comment-branch-remote {
    /* git-commit-comment-branch-remote */
    color: #8787d7;
}
.org-git-commit-comment-detached {
    /* git-commit-comment-detached */
    color: #8787d7;
}
.org-git-commit-comment-file {
    /* git-commit-comment-file */
    color: #2aa198;
}
.org-git-commit-comment-heading {
    /* git-commit-comment-heading */
    color: #268bd2;
    font-weight: bold;
}
.org-git-commit-keyword {
    /* git-commit-keyword */
    color: #2aa198;
}
.org-git-commit-known-pseudo-header {
    /* git-commit-known-pseudo-header */
    color: #268bd2;
    font-weight: bold;
}
.org-git-commit-nonempty-second-line {
    /* git-commit-nonempty-second-line */
    color: #dc752f;
    background-color: #262626;
}
.org-git-commit-overlong-summary {
    /* git-commit-overlong-summary */
    color: #dc752f;
    background-color: #262626;
}
.org-git-commit-pseudo-header {
    /* git-commit-pseudo-header */
    color: #2aa198;
}
.org-git-commit-summary {
    /* git-commit-summary */
    color: #df005f;
    font-weight: bold;
}
.org-git-rebase-comment-hash {
    /* git-rebase-comment-hash */
    color: #8787d7;
    font-weight: bold;
}
.org-git-rebase-comment-heading {
    /* git-rebase-comment-heading */
    color: #268bd2;
    font-weight: bold;
}
.org-git-rebase-description {
}
.org-git-rebase-hash {
    /* git-rebase-hash */
    color: #8787d7;
}
.org-git-rebase-killed-action {
    /* git-rebase-killed-action */
    color: #008787;
    background-color: #262626;
    text-decoration: line-through;
}
.org-git-rebase-label {
    /* git-rebase-label */
    color: #cccccc;
}
.org-glyphless-char {
    /* glyphless-char */
    text-decoration: underline;
}
.org-gnus-group-mail-1 {
    /* gnus-group-mail-1 */
    color: #e1ffe1;
    font-weight: bold;
}
.org-gnus-group-mail-1-empty {
    /* gnus-group-mail-1-empty */
    color: #e1ffe1;
}
.org-gnus-group-mail-2 {
    /* gnus-group-mail-2 */
    color: #c1ffc1;
    font-weight: bold;
}
.org-gnus-group-mail-2-empty {
    /* gnus-group-mail-2-empty */
    color: #c1ffc1;
}
.org-gnus-group-mail-3 {
    /* gnus-group-mail-3 */
    color: #7fffd4;
    font-weight: bold;
}
.org-gnus-group-mail-3-empty {
    /* gnus-group-mail-3-empty */
    color: #7fffd4;
}
.org-gnus-group-mail-low {
    /* gnus-group-mail-low */
    color: #76eec6;
    font-weight: bold;
}
.org-gnus-group-mail-low-empty {
    /* gnus-group-mail-low-empty */
    color: #76eec6;
}
.org-gnus-group-news-1 {
    /* gnus-group-news-1 */
    color: #afeeee;
    font-weight: bold;
}
.org-gnus-group-news-1-empty {
    /* gnus-group-news-1-empty */
    color: #afeeee;
}
.org-gnus-group-news-2 {
    /* gnus-group-news-2 */
    color: #40e0d0;
    font-weight: bold;
}
.org-gnus-group-news-2-empty {
    /* gnus-group-news-2-empty */
    color: #40e0d0;
}
.org-gnus-group-news-3 {
    /* gnus-group-news-3 */
    font-weight: bold;
}
.org-gnus-group-news-3-empty {
}
.org-gnus-group-news-4 {
    /* gnus-group-news-4 */
    font-weight: bold;
}
.org-gnus-group-news-4-empty {
}
.org-gnus-group-news-5 {
    /* gnus-group-news-5 */
    font-weight: bold;
}
.org-gnus-group-news-5-empty {
}
.org-gnus-group-news-6 {
    /* gnus-group-news-6 */
    font-weight: bold;
}
.org-gnus-group-news-6-empty {
}
.org-gnus-group-news-low {
    /* gnus-group-news-low */
    color: #00ced1;
    font-weight: bold;
}
.org-gnus-group-news-low-empty {
    /* gnus-group-news-low-empty */
    color: #00ced1;
}
.org-gnus-splash {
    /* gnus-splash */
    color: #cccccc;
}
.org-gnus-summary-cancelled {
    /* gnus-summary-cancelled */
    color: #262626;
    background-color: #dc752f;
}
.org-gnus-summary-high-ancient {
    /* gnus-summary-high-ancient */
    color: #87ceeb;
    font-weight: bold;
}
.org-gnus-summary-high-read {
    /* gnus-summary-high-read */
    color: #98fb98;
    font-weight: bold;
}
.org-gnus-summary-high-ticked {
    /* gnus-summary-high-ticked */
    color: #ffc0cb;
    font-weight: bold;
}
.org-gnus-summary-high-undownloaded {
    /* gnus-summary-high-undownloaded */
    color: #d3d3d3;
    font-weight: bold;
}
.org-gnus-summary-high-unread {
    /* gnus-summary-high-unread */
    font-weight: bold;
}
.org-gnus-summary-low-ancient {
    /* gnus-summary-low-ancient */
    color: #87ceeb;
    font-style: italic;
}
.org-gnus-summary-low-read {
    /* gnus-summary-low-read */
    color: #98fb98;
    font-style: italic;
}
.org-gnus-summary-low-ticked {
    /* gnus-summary-low-ticked */
    color: #ffc0cb;
    font-style: italic;
}
.org-gnus-summary-low-undownloaded {
    /* gnus-summary-low-undownloaded */
    color: #d3d3d3;
    font-style: italic;
}
.org-gnus-summary-low-unread {
    /* gnus-summary-low-unread */
    font-style: italic;
}
.org-gnus-summary-normal-ancient {
    /* gnus-summary-normal-ancient */
    color: #87ceeb;
}
.org-gnus-summary-normal-read {
    /* gnus-summary-normal-read */
    color: #98fb98;
}
.org-gnus-summary-normal-ticked {
    /* gnus-summary-normal-ticked */
    color: #ffc0cb;
}
.org-gnus-summary-normal-undownloaded {
    /* gnus-summary-normal-undownloaded */
    color: #d3d3d3;
}
.org-gnus-summary-normal-unread {
}
.org-gnus-summary-selected {
    /* gnus-summary-selected */
    text-decoration: underline;
}
.org-header-line {
    /* header-line */
    background-color: #080808;
}
.org-header-line-highlight {
    /* header-line-highlight */
    color: #b2b2b2;
    background-color: #444444;
}
.org-helm-action {
    /* helm-action */
    text-decoration: underline;
}
.org-helm-bookmark-addressbook {
    /* helm-bookmark-addressbook */
    color: #ff6347;
}
.org-helm-bookmark-directory {
    /* helm-bookmark-directory */
    color: #268bd2;
    background-color: #262626;
    font-weight: bold;
}
.org-helm-bookmark-file {
    /* helm-bookmark-file */
    color: #b2b2b2;
}
.org-helm-bookmark-file-not-found {
    /* helm-bookmark-file-not-found */
    color: #6c7b8b;
}
.org-helm-bookmark-gnus {
    /* helm-bookmark-gnus */
    color: #d75fd7;
}
.org-helm-bookmark-info {
    /* helm-bookmark-info */
    color: #d75fd7;
}
.org-helm-bookmark-man {
    /* helm-bookmark-man */
    color: #d75fd7;
}
.org-helm-bookmark-w3m {
    /* helm-bookmark-w3m */
    color: #d75fd7;
}
.org-helm-buffer-archive {
    /* helm-buffer-archive */
    color: #ffd700;
}
.org-helm-buffer-directory {
    /* helm-buffer-directory */
    color: #b2b2b2;
    background-color: #262626;
}
.org-helm-buffer-file {
    /* helm-buffer-file */
    color: #b2b2b2;
    background-color: #262626;
}
.org-helm-buffer-modified {
    /* helm-buffer-modified */
    color: #008787;
    background-color: #262626;
}
.org-helm-buffer-not-saved {
    /* helm-buffer-not-saved */
    color: #d75fd7;
    background-color: #262626;
}
.org-helm-buffer-process {
    /* helm-buffer-process */
    color: #268bd2;
    background-color: #262626;
}
.org-helm-buffer-saved-out {
    /* helm-buffer-saved-out */
    color: #b2b2b2;
    background-color: #262626;
}
.org-helm-buffer-size {
    /* helm-buffer-size */
    color: #b2b2b2;
    background-color: #262626;
}
.org-helm-candidate-number {
    /* helm-candidate-number */
    color: #268bd2;
    background-color: #262626;
    font-weight: bold;
}
.org-helm-candidate-number-suspended {
    /* helm-candidate-number-suspended */
    color: #268bd2;
    background-color: #262626;
    font-weight: bold;
}
.org-helm-delete-async-message {
    /* helm-delete-async-message */
    color: #ffff00;
}
.org-helm-etags-file {
    /* helm-etags-file */
    color: #8b814c;
    text-decoration: underline;
}
.org-helm-ff-denied {
    /* helm-ff-denied */
    color: #ff0000;
    background-color: #000000;
}
.org-helm-ff-directory {
    /* helm-ff-directory */
    color: #268bd2;
    background-color: #262626;
    font-weight: bold;
}
.org-helm-ff-dirs {
    /* helm-ff-dirs */
    color: #d75fd7;
    font-weight: bold;
}
.org-helm-ff-dotted-directory {
    /* helm-ff-dotted-directory */
    color: #268bd2;
    background-color: #262626;
    font-weight: bold;
}
.org-helm-ff-dotted-symlink-directory {
    /* helm-ff-dotted-symlink-directory */
    color: #00ffff;
    background-color: #262626;
    font-weight: bold;
}
.org-helm-ff-executable {
    /* helm-ff-executable */
    color: #86dc2f;
    background-color: #262626;
}
.org-helm-ff-file {
    /* helm-ff-file */
    color: #b2b2b2;
    background-color: #262626;
}
.org-helm-ff-invalid-symlink {
    /* helm-ff-invalid-symlink */
    color: #d70000;
    background-color: #262626;
    font-weight: bold;
}
.org-helm-ff-pipe {
    /* helm-ff-pipe */
    color: #ffff00;
    background-color: #000000;
}
.org-helm-ff-prefix {
    /* helm-ff-prefix */
    color: #262626;
    background-color: #268bd2;
}
.org-helm-ff-socket {
    /* helm-ff-socket */
    color: #ff1493;
}
.org-helm-ff-suid {
    /* helm-ff-suid */
    color: #ffffff;
    background-color: #ff0000;
}
.org-helm-ff-symlink {
    /* helm-ff-symlink */
    color: #00ffff;
    background-color: #262626;
    font-weight: bold;
}
.org-helm-ff-truename {
    /* helm-ff-truename */
    color: #2aa198;
}
.org-helm-grep-cmd-line {
    /* helm-grep-cmd-line */
    color: #b2b2b2;
    background-color: #262626;
}
.org-helm-grep-file {
    /* helm-grep-file */
    color: #b2b2b2;
    background-color: #262626;
}
.org-helm-grep-finish {
    /* helm-grep-finish */
    color: #b2b2b2;
    background-color: #262626;
}
.org-helm-grep-lineno {
    /* helm-grep-lineno */
    color: #df005f;
    background-color: #262626;
    font-weight: bold;
}
.org-helm-grep-match {
    /* helm-grep-match */
    color: #268bd2;
    background-color: #262626;
}
.org-helm-header {
    /* helm-header */
    color: #b2b2b2;
    background-color: #262626;
}
.org-helm-header-line-left-margin {
    /* helm-header-line-left-margin */
    color: #268bd2;
}
.org-helm-helper {
    /* helm-helper */
    color: #b2b2b2;
    background-color: #262626;
}
.org-helm-history-deleted {
    /* helm-history-deleted */
    color: #d70000;
    background-color: #262626;
    font-weight: bold;
}
.org-helm-history-remote {
    /* helm-history-remote */
    color: #ff6a6a;
}
.org-helm-lisp-completion-info {
    /* helm-lisp-completion-info */
    color: #ff0000;
}
.org-helm-lisp-show-completion {
    /* helm-lisp-show-completion */
    background-color: #2f4f4f;
}
.org-helm-locate-finish {
    /* helm-locate-finish */
    color: #00ff00;
}
.org-helm-m-x-key {
    /* helm-M-x-key */
    color: #ffa500;
    text-decoration: underline;
}
.org-helm-match {
    /* helm-match */
    color: #268bd2;
    background-color: #262626;
}
.org-helm-match-item {
    /* helm-match-item */
    color: #268bd2;
    background-color: #262626;
}
.org-helm-minibuffer-prompt {
    /* helm-minibuffer-prompt */
    color: #268bd2;
    font-weight: bold;
}
.org-helm-moccur-buffer {
    /* helm-moccur-buffer */
    color: #8787d7;
    background-color: #262626;
}
.org-helm-non-file-buffer {
    /* helm-non-file-buffer */
    text-decoration: underline;
}
.org-helm-prefarg {
    /* helm-prefarg */
    color: #00ff00;
}
.org-helm-resume-need-update {
    /* helm-resume-need-update */
    background-color: #ff0000;
}
.org-helm-selection {
    /* helm-selection */
    background-color: #444444;
}
.org-helm-selection-line {
    /* helm-selection-line */
    background-color: #1c1c1c;
}
.org-helm-separator {
    /* helm-separator */
    color: #d75fd7;
    background-color: #262626;
}
.org-helm-source-header {
    /* helm-source-header */
    color: #262626;
    background-color: #d75fd7;
    font-weight: bold;
}
.org-helm-visible-mark {
    /* helm-visible-mark */
    color: #268bd2;
    background-color: #121212;
}
.org-help-argument-name {
    /* help-argument-name */
    text-decoration: underline;
}
.org-highlight {
    /* highlight */
    color: #b2b2b2;
    background-color: #444444;
}
.org-highlight-numbers-number {
    /* highlight-numbers-number */
    color: #d75fd7;
}
.org-hl-line {
    /* hl-line */
    background-color: #1c1c1c;
}
.org-hl-paren {
}
.org-hl-todo {
    /* hl-todo */
    color: #cc9393;
    font-weight: bold;
}
.org-holiday {
    /* holiday */
    background-color: #8b4513;
}
.org-homoglyph {
    /* homoglyph */
    color: #00ffff;
}
.org-hydra-face-amaranth {
    /* hydra-face-amaranth */
    color: #E52B50;
    font-weight: bold;
}
.org-hydra-face-blue {
    /* hydra-face-blue */
    color: #268bd2;
}
.org-hydra-face-pink {
    /* hydra-face-pink */
    color: #FF6EB4;
    font-weight: bold;
}
.org-hydra-face-red {
    /* hydra-face-red */
    color: #d70000;
}
.org-hydra-face-teal {
    /* hydra-face-teal */
    color: #367588;
    font-weight: bold;
}
.org-ibuffer-locked-buffer {
    /* ibuffer-locked-buffer */
    color: #bc8f8f;
}
.org-ido-first-match {
    /* ido-first-match */
    color: #d75fd7;
    font-weight: bold;
}
.org-ido-incomplete-regexp {
    /* ido-incomplete-regexp */
    color: #dc752f;
    background-color: #262626;
}
.org-ido-indicator {
    /* ido-indicator */
    color: #ffff00;
    background-color: #ff0000;
}
.org-ido-only-match {
    /* ido-only-match */
    color: #86dc2f;
    font-weight: bold;
}
.org-ido-subdir {
    /* ido-subdir */
    color: #268bd2;
}
.org-ido-vertical-first-match {
    /* ido-vertical-first-match-face */
    color: #d75fd7;
    font-weight: bold;
}
.org-ido-vertical-match {
    /* ido-vertical-match-face */
    color: #d75fd7;
}
.org-ido-vertical-only-match {
    /* ido-vertical-only-match-face */
    color: #86dc2f;
    font-weight: bold;
}
.org-ido-virtual {
    /* ido-virtual */
    color: #268bd2;
}
.org-imenu-list-entry {
}
.org-imenu-list-entry-face-0 {
    /* imenu-list-entry-face-0 */
    color: #ffd700;
}
.org-imenu-list-entry-face-1 {
    /* imenu-list-entry-face-1 */
    color: #90ee90;
}
.org-imenu-list-entry-face-2 {
    /* imenu-list-entry-face-2 */
    color: #add8e6;
}
.org-imenu-list-entry-face-3 {
    /* imenu-list-entry-face-3 */
    color: #f4a460;
}
.org-imenu-list-entry-subalist-face-0 {
    /* imenu-list-entry-subalist-face-0 */
    color: #ffd700;
    font-weight: bold;
    text-decoration: underline;
}
.org-imenu-list-entry-subalist-face-1 {
    /* imenu-list-entry-subalist-face-1 */
    color: #90ee90;
    font-weight: bold;
    text-decoration: underline;
}
.org-imenu-list-entry-subalist-face-2 {
    /* imenu-list-entry-subalist-face-2 */
    color: #add8e6;
    font-weight: bold;
    text-decoration: underline;
}
.org-imenu-list-entry-subalist-face-3 {
    /* imenu-list-entry-subalist-face-3 */
    color: #f4a460;
    font-weight: bold;
    text-decoration: underline;
}
.org-info-command-ref-item {
    /* info-command-ref-item */
    color: #7474FFFF7474;
    background-color: #696969;
}
.org-info-constant-ref-item {
    /* info-constant-ref-item */
    color: #ff1493;
    background-color: #696969;
}
.org-info-double-quoted-name {
    /* info-double-quoted-name */
    color: #00ffff;
}
.org-info-emphasis {
    /* info-emphasis */
    text-decoration: underline;
}
.org-info-file {
    /* info-file */
    color: #ffff00;
    background-color: #696969;
}
.org-info-function-ref-item {
    /* info-function-ref-item */
    color: #4D4DDDDDDDDD;
    background-color: #696969;
}
.org-info-header-node {
    /* info-header-node */
    color: #d75fd7;
    font-weight: bold;
}
.org-info-header-xref {
    /* info-header-xref */
    color: #d75fd7;
    text-decoration: underline;
}
.org-info-index-match {
    /* info-index-match */
    color: #86dc2f;
    background-color: #444444;
}
.org-info-macro-ref-item {
    /* info-macro-ref-item */
    color: #ffff00;
    background-color: #696969;
}
.org-info-menu {
    /* info-menu */
    color: #86dc2f;
}
.org-info-menu-header {
    /* info-menu-header */
    font-weight: bold;
    text-decoration: underline;
}
.org-info-menu-star {
    /* info-menu-star */
    color: #ff0000;
}
.org-info-node {
    /* info-node */
    color: #d75fd7;
    font-weight: bold;
}
.org-info-quoted {
}
.org-info-quoted-name {
    /* info-quoted-name */
    color: #268bd2;
}
.org-info-reference-item {
    /* info-reference-item */
    font-weight: bold;
    text-decoration: underline;
}
.org-info-single-quote {
    /* info-single-quote */
    color: #00ff00;
    font-weight: bold;
}
.org-info-special-form-ref-item {
    /* info-special-form-ref-item */
    color: #ffff00;
    background-color: #696969;
}
.org-info-string {
    /* info-string */
    color: #2aa198;
}
.org-info-syntax-class-item {
    /* info-syntax-class-item */
    color: #FFFF9B9BFFFF;
    background-color: #696969;
}
.org-info-title-1 {
    /* info-title-1 */
    font-size: 140%;
    font-weight: bold;
}
.org-info-title-2 {
    /* info-title-2 */
    font-size: 130%;
    font-weight: bold;
}
.org-info-title-3 {
    /* info-title-3 */
    font-size: 130%;
}
.org-info-title-4 {
    /* info-title-4 */
    font-size: 120%;
}
.org-info-user-option-ref-item {
    /* info-user-option-ref-item */
    color: #ff0000;
    background-color: #696969;
}
.org-info-variable-ref-item {
    /* info-variable-ref-item */
    color: #ffa500;
    background-color: #696969;
}
.org-info-xref {
    /* info-xref */
    color: #008787;
    text-decoration: underline;
}
.org-internal-border {
}
.org-isearch {
    /* isearch */
    color: #262626;
    background-color: #86dc2f;
}
.org-isearch-fail {
    /* isearch-fail */
    background-color: #8b0000;
}
.org-italic {
    /* italic */
    text-decoration: underline;
}
.org-json-mode-object-name {
    /* json-mode-object-name-face */
    color: #8787d7;
}
.org-keyword {
    /* font-lock-keyword-face */
    color: #268bd2;
    font-weight: bold;
}
.org-lazy-highlight {
    /* lazy-highlight */
    background-color: #262626;
}
.org-line-number {
    /* line-number */
    color: #444444;
    background-color: #1c1c1c;
}
.org-line-number-current-line {
    /* line-number-current-line */
    color: #b2b2b2;
    background-color: #1c1c1c;
}
.org-link {
    /* link */
    color: #008787;
    text-decoration: underline;
}
.org-link-visited {
    /* link-visited */
    color: #d75fd7;
    text-decoration: underline;
}
.org-log-edit-header {
    /* log-edit-header */
    color: #268bd2;
    font-weight: bold;
}
.org-log-edit-summary {
    /* log-edit-summary */
    color: #d75fd7;
    font-weight: bold;
}
.org-log-edit-unknown-header {
    /* log-edit-unknown-header */
    color: #008787;
    background-color: #262626;
}
.org-lv-separator {
    /* lv-separator */
    background-color: #4d4d4d;
}
.org-magit-bisect-bad {
    /* magit-bisect-bad */
    color: #8b3a3a;
}
.org-magit-bisect-good {
    /* magit-bisect-good */
    color: #556b2f;
}
.org-magit-bisect-skip {
    /* magit-bisect-skip */
    color: #b8860b;
}
.org-magit-blame-date {
    /* magit-blame-date */
    color: #67b11d;
    background-color: #262626;
}
.org-magit-blame-dimmed {
    /* magit-blame-dimmed */
    color: #7f7f7f;
}
.org-magit-blame-hash {
    /* magit-blame-hash */
    color: #d75fd7;
    background-color: #262626;
}
.org-magit-blame-heading {
    /* magit-blame-heading */
    color: #67b11d;
    background-color: #262626;
}
.org-magit-blame-highlight {
    /* magit-blame-highlight */
    color: #ffffff;
    background-color: #404040;
}
.org-magit-blame-margin {
    /* magit-blame-margin */
    color: #ffffff;
    background-color: #404040;
}
.org-magit-blame-name {
    /* magit-blame-name */
    color: #875f00;
    background-color: #262626;
}
.org-magit-blame-summary {
    /* magit-blame-summary */
    color: #875f00;
    background-color: #262626;
}
.org-magit-branch-current {
    /* magit-branch-current */
    color: #268bd2;
    background-color: #262626;
    font-weight: bold;
}
.org-magit-branch-local {
    /* magit-branch-local */
    color: #268bd2;
    background-color: #262626;
    font-weight: bold;
}
.org-magit-branch-remote {
    /* magit-branch-remote */
    color: #2aa198;
    background-color: #262626;
    font-weight: bold;
}
.org-magit-branch-remote-head {
    /* magit-branch-remote-head */
    color: #2aa198;
    background-color: #262626;
    font-weight: bold;
}
.org-magit-branch-upstream {
    /* magit-branch-upstream */
    font-style: italic;
}
.org-magit-cherry-equivalent {
    /* magit-cherry-equivalent */
    color: #ff00ff;
}
.org-magit-cherry-unmatched {
    /* magit-cherry-unmatched */
    color: #00ffff;
}
.org-magit-diff-added {
    /* magit-diff-added */
    color: #ddffdd;
    background-color: #335533;
}
.org-magit-diff-added-highlight {
    /* magit-diff-added-highlight */
    color: #cceecc;
    background-color: #336633;
}
.org-magit-diff-base {
    /* magit-diff-base */
    color: #ffffcc;
    background-color: #555522;
}
.org-magit-diff-base-highlight {
    /* magit-diff-base-highlight */
    color: #eeeebb;
    background-color: #666622;
}
.org-magit-diff-conflict-heading {
    /* magit-diff-conflict-heading */
    color: #888888;
    background-color: #444444;
}
.org-magit-diff-context {
    /* magit-diff-context */
    color: #b3b3b3;
}
.org-magit-diff-context-highlight {
    /* magit-diff-context-highlight */
    color: #b2b2b2;
    background-color: #1c1c1c;
}
.org-magit-diff-file-heading {
    /* magit-diff-file-heading */
    font-weight: bold;
}
.org-magit-diff-file-heading-selection {
    /* magit-diff-file-heading-selection */
    color: #cd8162;
    background-color: #1c1c1c;
}
.org-magit-diff-hunk-heading {
    /* magit-diff-hunk-heading */
    color: #888888;
    background-color: #444444;
}
.org-magit-diff-hunk-heading-highlight {
    /* magit-diff-hunk-heading-highlight */
    color: #b2b2b2;
    background-color: #333333;
}
.org-magit-diff-hunk-heading-selection {
    /* magit-diff-hunk-heading-selection */
    color: #cd8162;
    background-color: #333333;
}
.org-magit-diff-hunk-region {
    /* magit-diff-hunk-region */
    font-weight: bold;
}
.org-magit-diff-lines-boundary {
    /* magit-diff-lines-boundary */
    color: #cccccc;
    background-color: #8b4c39;
}
.org-magit-diff-lines-heading {
    /* magit-diff-lines-heading */
    color: #cccccc;
    background-color: #8b4c39;
}
.org-magit-diff-our {
    /* magit-diff-our */
    color: #ffdddd;
    background-color: #553333;
}
.org-magit-diff-our-highlight {
    /* magit-diff-our-highlight */
    color: #eecccc;
    background-color: #663333;
}
.org-magit-diff-removed {
    /* magit-diff-removed */
    color: #ffdddd;
    background-color: #553333;
}
.org-magit-diff-removed-highlight {
    /* magit-diff-removed-highlight */
    color: #eecccc;
    background-color: #663333;
}
.org-magit-diff-their {
    /* magit-diff-their */
    color: #ddffdd;
    background-color: #335533;
}
.org-magit-diff-their-highlight {
    /* magit-diff-their-highlight */
    color: #cceecc;
    background-color: #336633;
}
.org-magit-diff-whitespace-warning {
    /* magit-diff-whitespace-warning */
    background-color: #008787;
}
.org-magit-diffstat-added {
    /* magit-diffstat-added */
    color: #448844;
}
.org-magit-diffstat-removed {
    /* magit-diffstat-removed */
    color: #aa4444;
}
.org-magit-dimmed {
    /* magit-dimmed */
    color: #7f7f7f;
}
.org-magit-filename {
}
.org-magit-hash {
    /* magit-hash */
    color: #8787d7;
}
.org-magit-head {
    /* magit-head */
    color: #268bd2;
    background-color: #262626;
    font-weight: bold;
}
.org-magit-header-line {
    /* magit-header-line */
    color: #268bd2;
    font-weight: bold;
}
.org-magit-header-line-key {
    /* magit-header-line-key */
    color: #268bd2;
}
.org-magit-header-line-log-select {
    /* magit-header-line-log-select */
    font-weight: bold;
}
.org-magit-keyword {
    /* magit-keyword */
    color: #2aa198;
}
.org-magit-keyword-squash {
    /* magit-keyword-squash */
    color: #dc752f;
    background-color: #262626;
}
.org-magit-log-author {
    /* magit-log-author */
    color: #d75fd7;
}
.org-magit-log-date {
    /* magit-log-date */
    color: #cccccc;
}
.org-magit-log-graph {
    /* magit-log-graph */
    color: #cccccc;
}
.org-magit-mode-line-process {
    /* magit-mode-line-process */
    font-weight: bold;
}
.org-magit-mode-line-process-error {
    /* magit-mode-line-process-error */
    color: #e0211d;
}
.org-magit-popup-argument {
    /* magit-popup-argument */
    color: #dc752f;
    background-color: #262626;
}
.org-magit-popup-disabled-argument {
    /* magit-popup-disabled-argument */
    color: #585858;
}
.org-magit-popup-heading {
    /* magit-popup-heading */
    color: #268bd2;
    font-weight: bold;
}
.org-magit-popup-key {
    /* magit-popup-key */
    color: #268bd2;
}
.org-magit-popup-option-value {
    /* magit-popup-option-value */
    color: #2aa198;
}
.org-magit-process-ng {
    /* magit-process-ng */
    color: #dc752f;
    font-weight: bold;
}
.org-magit-process-ok {
    /* magit-process-ok */
    color: #d75fd7;
    font-weight: bold;
}
.org-magit-reflog-amend {
    /* magit-reflog-amend */
    color: #af00df;
}
.org-magit-reflog-checkout {
    /* magit-reflog-checkout */
    color: #268bd2;
}
.org-magit-reflog-cherry-pick {
    /* magit-reflog-cherry-pick */
    color: #67b11d;
}
.org-magit-reflog-commit {
    /* magit-reflog-commit */
    color: #67b11d;
}
.org-magit-reflog-merge {
    /* magit-reflog-merge */
    color: #67b11d;
}
.org-magit-reflog-other {
    /* magit-reflog-other */
    color: #00ffff;
}
.org-magit-reflog-rebase {
    /* magit-reflog-rebase */
    color: #af00df;
}
.org-magit-reflog-remote {
    /* magit-reflog-remote */
    color: #00ffff;
}
.org-magit-reflog-reset {
    /* magit-reflog-reset */
    color: #d70000;
}
.org-magit-refname {
    /* magit-refname */
    color: #cccccc;
}
.org-magit-refname-pullreq {
    /* magit-refname-pullreq */
    color: #cccccc;
}
.org-magit-refname-stash {
    /* magit-refname-stash */
    color: #cccccc;
}
.org-magit-refname-wip {
    /* magit-refname-wip */
    color: #cccccc;
}
.org-magit-section-heading {
    /* magit-section-heading */
    color: #268bd2;
    font-weight: bold;
}
.org-magit-section-heading-selection {
    /* magit-section-heading-selection */
    color: #cd8162;
}
.org-magit-section-highlight {
    /* magit-section-highlight */
    background-color: #1c1c1c;
}
.org-magit-section-secondary-heading {
    /* magit-section-secondary-heading */
    font-weight: bold;
}
.org-magit-sequence-done {
    /* magit-sequence-done */
    color: #8787d7;
}
.org-magit-sequence-drop {
    /* magit-sequence-drop */
    color: #cd5c5c;
}
.org-magit-sequence-exec {
    /* magit-sequence-exec */
    color: #8787d7;
}
.org-magit-sequence-head {
    /* magit-sequence-head */
    color: #b0e2ff;
}
.org-magit-sequence-onto {
    /* magit-sequence-onto */
    color: #8787d7;
}
.org-magit-sequence-part {
    /* magit-sequence-part */
    color: #eedc82;
}
.org-magit-sequence-pick {
    /* magit-sequence-pick */
    color: #b2b2b2;
    background-color: #262626;
}
.org-magit-sequence-stop {
    /* magit-sequence-stop */
    color: #b4eeb4;
}
.org-magit-signature-bad {
    /* magit-signature-bad */
    color: #ff0000;
    font-weight: bold;
}
.org-magit-signature-error {
    /* magit-signature-error */
    color: #cd2626;
}
.org-magit-signature-expired {
    /* magit-signature-expired */
    color: #ffa500;
}
.org-magit-signature-expired-key {
    /* magit-signature-expired-key */
    color: #ffa500;
}
.org-magit-signature-good {
    /* magit-signature-good */
    color: #00ff00;
}
.org-magit-signature-revoked {
    /* magit-signature-revoked */
    color: #d02090;
}
.org-magit-signature-untrusted {
    /* magit-signature-untrusted */
    color: #00ffff;
}
.org-magit-tag {
    /* magit-tag */
    color: #eedc82;
}
.org-match {
    /* match */
    color: #86dc2f;
    background-color: #444444;
}
.org-menu {
}
.org-message-cited-text {
    /* message-cited-text */
    color: #ffaeb9;
}
.org-message-header-cc {
    /* message-header-cc */
    color: #7fff00;
    font-weight: bold;
}
.org-message-header-name {
    /* message-header-name */
    color: #00ff00;
}
.org-message-header-newsgroups {
    /* message-header-newsgroups */
    color: #ffff00;
    font-weight: bold;
    font-style: italic;
}
.org-message-header-other {
    /* message-header-other */
    color: #ff3e96;
}
.org-message-header-subject {
    /* message-header-subject */
    color: #c0ff3e;
}
.org-message-header-to {
    /* message-header-to */
    color: #caff70;
    font-weight: bold;
}
.org-message-header-xheader {
    /* message-header-xheader */
    color: #00bfff;
}
.org-message-mml {
    /* message-mml */
    color: #00fa9a;
}
.org-message-separator {
    /* message-separator */
    color: #b0e2ff;
}
.org-minibuffer-prompt {
    /* minibuffer-prompt */
    color: #268bd2;
    font-weight: bold;
}
.org-mm-command-output {
    /* mm-command-output */
    color: #228b22;
}
.org-mode-line {
    /* mode-line */
    color: #b2b2b2;
    background-color: #121212;
}
.org-mode-line-buffer-id {
    /* mode-line-buffer-id */
    color: #d75fd7;
    font-weight: bold;
}
.org-mode-line-buffer-id-inactive {
    /* mode-line-buffer-id-inactive */
    color: #d75fd7;
    font-weight: bold;
}
.org-mode-line-emphasis {
    /* mode-line-emphasis */
    font-weight: bold;
}
.org-mode-line-highlight {
}
.org-mode-line-inactive {
    /* mode-line-inactive */
    color: #b2b2b2;
    background-color: #262626;
}
.org-mouse {
}
.org-mouse-drag-and-drop-region {
    /* mouse-drag-and-drop-region */
    background-color: #444444;
}
.org-negation-char {
    /* font-lock-negation-char-face */
    color: #d75fd7;
}
.org-next-error {
    /* next-error */
    background-color: #444444;
}
.org-nobreak-hyphen {
    /* nobreak-hyphen */
    color: #00ffff;
}
.org-nobreak-space {
    /* nobreak-space */
    color: #00ffff;
    text-decoration: underline;
}
.org-ns-working-text {
    /* ns-working-text-face */
    text-decoration: underline;
}
.org-org-agenda-calendar-event {
    /* org-agenda-calendar-event */
    color: #b2b2b2;
    background-color: #262626;
}
.org-org-agenda-calendar-sexp {
    /* org-agenda-calendar-sexp */
    color: #b2b2b2;
    background-color: #262626;
}
.org-org-agenda-clocking {
    /* org-agenda-clocking */
    color: #d75fd7;
    background-color: #444444;
}
.org-org-agenda-column-dateline {
    /* org-agenda-column-dateline */
    background-color: #444444;
}
.org-org-agenda-current-time {
    /* org-agenda-current-time */
    color: #2aa198;
}
.org-org-agenda-date {
    /* org-agenda-date */
    color: #8787d7;
}
.org-org-agenda-date-today {
    /* org-agenda-date-today */
    color: #268bd2;
    font-weight: bold;
}
.org-org-agenda-date-weekend {
    /* org-agenda-date-weekend */
    color: #8787d7;
    font-weight: bold;
}
.org-org-agenda-diary {
    /* org-agenda-diary */
    color: #b2b2b2;
    background-color: #262626;
}
.org-org-agenda-dimmed-todo {
    /* org-agenda-dimmed-todo-face */
    color: #7f7f7f;
}
.org-org-agenda-done {
    /* org-agenda-done */
    color: #86dc2f;
}
.org-org-agenda-filter-category {
    /* org-agenda-filter-category */
    color: #b2b2b2;
    background-color: #121212;
}
.org-org-agenda-filter-effort {
    /* org-agenda-filter-effort */
    color: #b2b2b2;
    background-color: #121212;
}
.org-org-agenda-filter-regexp {
    /* org-agenda-filter-regexp */
    color: #b2b2b2;
    background-color: #121212;
}
.org-org-agenda-filter-tags {
    /* org-agenda-filter-tags */
    color: #b2b2b2;
    background-color: #121212;
}
.org-org-agenda-restriction-lock {
    /* org-agenda-restriction-lock */
    background-color: #1C1C1C;
}
.org-org-agenda-structure {
    /* org-agenda-structure */
    color: #d75fd7;
    font-weight: bold;
}
.org-org-archived {
    /* org-archived */
    color: #585858;
}
.org-org-block {
    /* org-block */
    color: #b2b2b2;
    background-color: #262626;
}
.org-org-block-begin-line {
    /* org-block-begin-line */
    color: #af5faf;
    background-color: #333333;
}
.org-org-block-end-line {
    /* org-block-end-line */
    color: #af5faf;
    background-color: #333333;
}
.org-org-checkbox {
    /* org-checkbox */
    font-weight: bold;
}
.org-org-checkbox-statistics-done {
    /* org-checkbox-statistics-done */
    color: #86dc2f;
    background-color: #262626;
    font-weight: bold;
}
.org-org-checkbox-statistics-todo {
    /* org-checkbox-statistics-todo */
    color: #dc752f;
    background-color: #262626;
    font-weight: bold;
}
.org-org-clock-overlay {
    /* org-clock-overlay */
    color: #d75fd7;
}
.org-org-code {
    /* org-code */
    color: #00ffff;
}
.org-org-column {
    /* org-column */
    background-color: #444444;
}
.org-org-column-title {
    /* org-column-title */
    background-color: #444444;
}
.org-org-date {
    /* org-date */
    color: #8787d7;
    text-decoration: underline;
}
.org-org-date-selected {
    /* org-date-selected */
    color: #262626;
    background-color: #d75fd7;
}
.org-org-default {
    /* org-default */
    color: #b2b2b2;
    background-color: #262626;
}
.org-org-document-info {
    /* org-document-info */
    color: #afeeee;
}
.org-org-document-info-keyword {
    /* org-document-info-keyword */
    color: #af875f;
}
.org-org-document-title {
    /* org-document-title */
    color: #d75fd7;
    font-size: 140%;
    font-weight: bold;
    text-decoration: underline;
}
.org-org-done {
    /* org-done */
    color: #86dc2f;
    background-color: #262626;
    font-weight: bold;
}
.org-org-drawer {
    /* org-drawer */
    color: #87cefa;
}
.org-org-ellipsis {
    /* org-ellipsis */
    color: #268bd2;
}
.org-org-footnote {
    /* org-footnote */
    color: #b2b2b2;
    text-decoration: underline;
}
.org-org-formula {
    /* org-formula */
    color: #ff7f24;
}
.org-org-habit-alert {
    /* org-habit-alert-face */
    background-color: #ffd700;
}
.org-org-habit-alert-future {
    /* org-habit-alert-future-face */
    background-color: #b8860b;
}
.org-org-habit-clear {
    /* org-habit-clear-face */
    background-color: #0000ff;
}
.org-org-habit-clear-future {
    /* org-habit-clear-future-face */
    background-color: #191970;
}
.org-org-habit-overdue {
    /* org-habit-overdue-face */
    background-color: #b22222;
}
.org-org-habit-overdue-future {
    /* org-habit-overdue-future-face */
    background-color: #8b0000;
}
.org-org-habit-ready {
    /* org-habit-ready-face */
    background-color: #228b22;
}
.org-org-habit-ready-future {
    /* org-habit-ready-future-face */
    background-color: #006400;
}
.org-org-headline-done {
    /* org-headline-done */
    color: #ffa07a;
}
.org-org-hide {
    /* org-hide */
    color: #262626;
}
.org-org-indent {
    /* org-indent */
    color: #262626;
}
.org-org-kbd {
    /* org-kbd */
    color: #b2b2b2;
    background-color: #444444;
}
.org-org-latex-and-related {
    /* org-latex-and-related */
    color: #deb887;
}
.org-org-level-1 {
    /* org-level-1 */
    color: #268bd2;
    font-size: 130%;
    font-weight: bold;
}
.org-org-level-2 {
    /* org-level-2 */
    color: #2aa198;
    font-size: 120%;
    font-weight: bold;
}
.org-org-level-3 {
    /* org-level-3 */
    color: #67b11d;
    font-size: 110%;
}
.org-org-level-4 {
    /* org-level-4 */
    color: #875f00;
}
.org-org-level-5 {
    /* org-level-5 */
    color: #268bd2;
}
.org-org-level-6 {
    /* org-level-6 */
    color: #2aa198;
}
.org-org-level-7 {
    /* org-level-7 */
    color: #67b11d;
}
.org-org-level-8 {
    /* org-level-8 */
    color: #875f00;
}
.org-org-link {
    /* org-link */
    color: #008787;
    text-decoration: underline;
}
.org-org-list-dt {
    /* org-list-dt */
    font-weight: bold;
}
.org-org-macro {
    /* org-macro */
    color: #deb887;
}
.org-org-meta-line {
    /* org-meta-line */
    color: #af875f;
}
.org-org-mode-line-clock {
    /* org-mode-line-clock */
    color: #b2b2b2;
    background-color: #121212;
}
.org-org-mode-line-clock-overrun {
    /* org-mode-line-clock-overrun */
    color: #e0211d;
}
.org-org-priority {
    /* org-priority */
    color: #dc752f;
    font-weight: bold;
}
.org-org-property-value {
}
.org-org-quote {
    /* org-quote */
    color: #b2b2b2;
    background-color: #262626;
    font-style: italic;
}
.org-org-scheduled {
    /* org-scheduled */
    color: #d75fd7;
}
.org-org-scheduled-previously {
    /* org-scheduled-previously */
    color: #b2b2b2;
    font-style: italic;
}
.org-org-scheduled-today {
    /* org-scheduled-today */
    color: #d75fd7;
}
.org-org-sexp-date {
    /* org-sexp-date */
    color: #b2b2b2;
}
.org-org-special-keyword {
    /* org-special-keyword */
    color: #d75fd7;
}
.org-org-super-agenda-header {
    /* org-super-agenda-header */
    color: #d75fd7;
    font-weight: bold;
}
.org-org-table {
    /* org-table */
    color: #b2b2b2;
    background-color: #262626;
}
.org-org-tag {
    /* org-tag */
    color: #af875f;
}
.org-org-tag-group {
    /* org-tag-group */
    color: #af875f;
}
.org-org-target {
    /* org-target */
    text-decoration: underline;
}
.org-org-time-grid {
    /* org-time-grid */
    color: #2aa198;
}
.org-org-todo {
    /* org-todo */
    color: #dc752f;
    background-color: #262626;
    font-weight: bold;
}
.org-org-upcoming-deadline {
    /* org-upcoming-deadline */
    color: #dc752f;
    font-weight: bold;
}
.org-org-upcoming-distant-deadline {
    /* org-upcoming-distant-deadline */
    color: #86dc2f;
    font-weight: bold;
}
.org-org-verbatim {
    /* org-verbatim */
    color: #268bd2;
}
.org-org-verse {
    /* org-verse */
    color: #b2b2b2;
    background-color: #262626;
    font-style: italic;
}
.org-org-warning {
    /* org-warning */
    color: #e0211d;
    font-weight: bold;
}
.org-origami-fold-fringe {
}
.org-origami-fold-header {
}
.org-outline-1 {
    /* outline-1 */
    color: #268bd2;
    font-size: 130%;
    font-weight: bold;
}
.org-outline-2 {
    /* outline-2 */
    color: #2aa198;
    font-size: 120%;
    font-weight: bold;
}
.org-outline-3 {
    /* outline-3 */
    color: #67b11d;
    font-size: 110%;
}
.org-outline-4 {
    /* outline-4 */
    color: #875f00;
}
.org-outline-5 {
    /* outline-5 */
    color: #268bd2;
}
.org-outline-6 {
    /* outline-6 */
    color: #2aa198;
}
.org-outline-7 {
    /* outline-7 */
    color: #67b11d;
}
.org-outline-8 {
    /* outline-8 */
    color: #875f00;
}
.org-package-description {
    /* package-description */
    color: #b2b2b2;
    background-color: #262626;
}
.org-package-name {
    /* package-name */
    color: #008787;
    text-decoration: underline;
}
.org-package-status-avail-obso {
    /* package-status-avail-obso */
    color: #e0211d;
}
.org-package-status-available {
    /* package-status-available */
    color: #b2b2b2;
    background-color: #262626;
}
.org-package-status-built-in {
    /* package-status-built-in */
    color: #268bd2;
}
.org-package-status-dependency {
    /* package-status-dependency */
    color: #008787;
    background-color: #262626;
}
.org-package-status-disabled {
    /* package-status-disabled */
    color: #dc752f;
    background-color: #262626;
}
.org-package-status-external {
    /* package-status-external */
    color: #268bd2;
}
.org-package-status-held {
    /* package-status-held */
    color: #d75fd7;
}
.org-package-status-incompat {
    /* package-status-incompat */
    color: #e0211d;
}
.org-package-status-installed {
    /* package-status-installed */
    color: #008787;
    background-color: #262626;
}
.org-package-status-unsigned {
    /* package-status-unsigned */
    color: #dc752f;
    background-color: #262626;
}
.org-page-break-lines {
    /* page-break-lines */
    color: #444444;
}
.org-persp-face-lighter-buffer-not-in-persp {
    /* persp-face-lighter-buffer-not-in-persp */
    color: #00F;
    background-color: #F00;
    font-weight: bold;
}
.org-persp-face-lighter-default {
    /* persp-face-lighter-default */
    text-decoration: underline;
}
.org-persp-face-lighter-nil-persp {
    /* persp-face-lighter-nil-persp */
    font-weight: bold;
    font-style: italic;
}
.org-php-Xthis {
    /* php-$this */
    color: #d75fd7;
}
.org-php-Xthis-sigil {
    /* php-$this-sigil */
    color: #d75fd7;
}
.org-php-builtin {
    /* php-builtin */
    color: #268bd2;
}
.org-php-constant {
    /* php-constant */
    color: #d75fd7;
}
.org-php-constant-assign {
    /* php-constant-assign */
    color: #df005f;
    font-weight: bold;
}
.org-php-doc-Xthis {
    /* php-doc-$this */
    color: #df005f;
    font-weight: bold;
}
.org-php-doc-Xthis-sigil {
    /* php-doc-$this-sigil */
    color: #df005f;
    font-weight: bold;
}
.org-php-doc-annotation-tag {
    /* php-doc-annotation-tag */
    color: #d75fd7;
}
.org-php-doc-class-name {
    /* php-doc-class-name */
    color: #2aa198;
}
.org-php-doc-variable-sigil {
    /* php-doc-variable-sigil */
    color: #8787d7;
}
.org-php-errorcontrol-op {
    /* php-errorcontrol-op */
    color: #df005f;
    font-weight: bold;
}
.org-php-extras-autocomplete-candidate {
    /* php-extras-autocomplete-candidate-face */
    color: #b2b2b2;
    background-color: #262626;
}
.org-php-extras-autocomplete-selection {
    /* php-extras-autocomplete-selection-face */
    color: #b2b2b2;
    background-color: #262626;
}
.org-php-function-call {
    /* php-function-call */
    color: #b2b2b2;
    background-color: #262626;
}
.org-php-function-name {
    /* php-function-name */
    color: #d75fd7;
    font-weight: bold;
}
.org-php-keyword {
    /* php-keyword */
    color: #268bd2;
    font-weight: bold;
}
.org-php-magical-constant {
    /* php-magical-constant */
    color: #268bd2;
}
.org-php-method-call {
    /* php-method-call */
    color: #b2b2b2;
    background-color: #262626;
}
.org-php-object-op {
    /* php-object-op */
    color: #b2b2b2;
    background-color: #262626;
}
.org-php-paamayim-nekudotayim {
    /* php-paamayim-nekudotayim */
    color: #b2b2b2;
    background-color: #262626;
}
.org-php-php-tag {
    /* php-php-tag */
    color: #d75fd7;
}
.org-php-property-name {
    /* php-property-name */
    color: #8787d7;
}
.org-php-static-method-call {
    /* php-static-method-call */
    color: #b2b2b2;
    background-color: #262626;
}
.org-php-string {
    /* php-string */
    color: #2aa198;
}
.org-php-type {
    /* php-type */
    color: #df005f;
    font-weight: bold;
}
.org-php-variable-name {
    /* php-variable-name */
    color: #8787d7;
}
.org-php-variable-sigil {
    /* php-variable-sigil */
    color: #b2b2b2;
    background-color: #262626;
}
.org-powerline-active0 {
    /* powerline-active0 */
    color: #b2b2b2;
    background-color: #121212;
}
.org-powerline-active1 {
    /* powerline-active1 */
    color: #b2b2b2;
    background-color: #444444;
}
.org-powerline-active2 {
    /* powerline-active2 */
    color: #b2b2b2;
    background-color: #444444;
}
.org-powerline-inactive0 {
    /* powerline-inactive0 */
    color: #b2b2b2;
    background-color: #262626;
}
.org-powerline-inactive1 {
    /* powerline-inactive1 */
    color: #b2b2b2;
    background-color: #1c1c1c;
}
.org-powerline-inactive2 {
    /* powerline-inactive2 */
    color: #b2b2b2;
    background-color: #1c1c1c;
}
.org-preprocessor {
    /* font-lock-preprocessor-face */
    color: #d75fd7;
}
.org-query-replace {
    /* query-replace */
    color: #262626;
    background-color: #86dc2f;
}
.org-rainbow-delimiters-base {
}
.org-rainbow-delimiters-depth-1 {
    /* rainbow-delimiters-depth-1-face */
    color: #268bd2;
}
.org-rainbow-delimiters-depth-2 {
    /* rainbow-delimiters-depth-2-face */
    color: #d75fd7;
}
.org-rainbow-delimiters-depth-3 {
    /* rainbow-delimiters-depth-3-face */
    color: #2aa198;
}
.org-rainbow-delimiters-depth-4 {
    /* rainbow-delimiters-depth-4-face */
    color: #67b11d;
}
.org-rainbow-delimiters-depth-5 {
    /* rainbow-delimiters-depth-5-face */
    color: #875f00;
}
.org-rainbow-delimiters-depth-6 {
    /* rainbow-delimiters-depth-6-face */
    color: #268bd2;
}
.org-rainbow-delimiters-depth-7 {
    /* rainbow-delimiters-depth-7-face */
    color: #d75fd7;
}
.org-rainbow-delimiters-depth-8 {
    /* rainbow-delimiters-depth-8-face */
    color: #2aa198;
}
.org-rainbow-delimiters-depth-9 {
    /* rainbow-delimiters-depth-9-face */
    color: #9cb6ad;
}
.org-rainbow-delimiters-mismatched {
    /* rainbow-delimiters-mismatched-face */
    color: #e0211d;
    text-decoration: overline;
}
.org-rainbow-delimiters-unmatched {
    /* rainbow-delimiters-unmatched-face */
    color: #e0211d;
    text-decoration: overline;
}
.org-rcirc-bright-nick {
    /* rcirc-bright-nick */
    color: #00ffff;
    background-color: #262626;
}
.org-rcirc-dim-nick {
    /* rcirc-dim-nick */
    color: #585858;
}
.org-rcirc-keyword {
    /* rcirc-keyword */
    color: #67b11d;
    background-color: #262626;
}
.org-rcirc-my-nick {
    /* rcirc-my-nick */
    color: #87cefa;
}
.org-rcirc-nick-in-message {
    /* rcirc-nick-in-message */
    color: #00ffff;
}
.org-rcirc-nick-in-message-full-line {
    /* rcirc-nick-in-message-full-line */
    font-weight: bold;
}
.org-rcirc-other-nick {
    /* rcirc-other-nick */
    color: #eedd82;
}
.org-rcirc-prompt {
    /* rcirc-prompt */
    color: #00ffff;
}
.org-rcirc-server {
    /* rcirc-server */
    color: #ff7f24;
}
.org-rcirc-server-prefix {
    /* rcirc-server-prefix */
    color: #ff7f24;
}
.org-rcirc-timestamp {
    /* rcirc-timestamp */
    color: #268bd2;
}
.org-rcirc-track-keyword {
    /* rcirc-track-keyword */
    color: #262626;
    background-color: #67b11d;
}
.org-rcirc-track-nick {
    /* rcirc-track-nick */
    color: #b2b2b2;
    background-color: #262626;
}
.org-rcirc-url {
    /* rcirc-url */
    color: #008787;
    text-decoration: underline;
}
.org-read-multiple-choice {
    /* read-multiple-choice-face */
    font-weight: bold;
    text-decoration: underline;
}
.org-reb-match-0 {
    /* reb-match-0 */
    background-color: #36648b;
}
.org-reb-match-1 {
    /* reb-match-1 */
    background-color: #0000cd;
}
.org-reb-match-2 {
    /* reb-match-2 */
    background-color: #458b00;
}
.org-reb-match-3 {
    /* reb-match-3 */
    background-color: #8b4726;
}
.org-rectangle-preview {
    /* rectangle-preview */
    background-color: #444444;
}
.org-regexp-grouping-backslash {
    /* font-lock-regexp-grouping-backslash */
    font-weight: bold;
}
.org-regexp-grouping-construct {
    /* font-lock-regexp-grouping-construct */
    font-weight: bold;
}
.org-region {
    /* region */
    background-color: #444444;
}
.org-rmail-header-name {
    /* rmail-header-name */
    color: #d75fd7;
    font-weight: bold;
}
.org-rmail-highlight {
    /* rmail-highlight */
    color: #b2b2b2;
    background-color: #444444;
}
.org-rxt-highlight {
}
.org-scroll-bar {
}
.org-secondary-selection {
    /* secondary-selection */
    background-color: #121212;
}
.org-sgml-namespace {
    /* sgml-namespace */
    color: #268bd2;
}
.org-sh-escaped-newline {
    /* sh-escaped-newline */
    color: #2aa198;
}
.org-sh-heredoc {
    /* sh-heredoc */
    color: #ffff00;
    font-weight: bold;
}
.org-sh-quoted-exec {
    /* sh-quoted-exec */
    color: #fa8072;
}
.org-shadow {
    /* shadow */
    color: #585858;
}
.org-show-paren-match {
    /* show-paren-match */
    color: #86dc2f;
    font-weight: bold;
    text-decoration: underline;
}
.org-show-paren-match-expression {
    /* show-paren-match-expression */
    background-color: #262626;
}
.org-show-paren-mismatch {
    /* show-paren-mismatch */
    color: #e0211d;
    font-weight: bold;
    text-decoration: underline;
}
.org-shr-link {
    /* shr-link */
    color: #008787;
    text-decoration: underline;
}
.org-shr-strike-through {
    /* shr-strike-through */
    text-decoration: line-through;
}
.org-smerge-base {
    /* smerge-base */
    background-color: #262626;
}
.org-smerge-lower {
    /* smerge-lower */
    background-color: #262626;
}
.org-smerge-markers {
    /* smerge-markers */
    color: #888888;
    background-color: #444444;
}
.org-smerge-refined-added {
    /* smerge-refined-added */
    color: #67b11d;
    background-color: #262626;
}
.org-smerge-refined-changed {
    /* smerge-refined-changed */
    color: #268bd2;
    background-color: #262626;
}
.org-smerge-refined-removed {
    /* smerge-refined-removed */
    color: #d70000;
    background-color: #262626;
}
.org-smerge-upper {
    /* smerge-upper */
    background-color: #262626;
}
.org-sp-pair-overlay {
    /* sp-pair-overlay-face */
    background-color: #444444;
}
.org-sp-show-pair-enclosing {
    /* sp-show-pair-enclosing */
    color: #b2b2b2;
    background-color: #444444;
}
.org-sp-show-pair-match {
    /* sp-show-pair-match-face */
    color: #86dc2f;
    font-weight: bold;
    text-decoration: underline;
}
.org-sp-show-pair-match-content {
}
.org-sp-show-pair-mismatch {
    /* sp-show-pair-mismatch-face */
    color: #e0211d;
    font-weight: bold;
    text-decoration: underline;
}
.org-sp-wrap-overlay {
    /* sp-wrap-overlay-face */
    background-color: #444444;
}
.org-sp-wrap-overlay-closing-pair {
    /* sp-wrap-overlay-closing-pair */
    color: #ff0000;
    background-color: #444444;
}
.org-sp-wrap-overlay-opening-pair {
    /* sp-wrap-overlay-opening-pair */
    color: #00ff00;
    background-color: #444444;
}
.org-sp-wrap-tag-overlay {
    /* sp-wrap-tag-overlay-face */
    background-color: #444444;
}
.org-spaceline-flycheck-error {
    /* spaceline-flycheck-error */
    color: #e0211d;
}
.org-spaceline-flycheck-info {
    /* spaceline-flycheck-info */
    color: #268bd2;
}
.org-spaceline-flycheck-warning {
    /* spaceline-flycheck-warning */
    color: #dc752f;
}
.org-spaceline-python-venv {
    /* spaceline-python-venv */
    color: #d75fd7;
}
.org-spacemacs-helm-navigation-ts {
    /* spacemacs-helm-navigation-ts-face */
    color: #000000;
}
.org-spacemacs-ido-navigation-ts {
    /* spacemacs-ido-navigation-ts-face */
    color: #000000;
    font-weight: bold;
}
.org-spacemacs-micro-state-binding {
    /* spacemacs-micro-state-binding-face */
    font-weight: bold;
}
.org-spacemacs-micro-state-header {
    /* spacemacs-micro-state-header-face */
    color: #000000;
    background-color: #eead0e;
    font-weight: bold;
}
.org-spacemacs-mode-line-new-version-lighter-error {
}
.org-spacemacs-mode-line-new-version-lighter-success {
}
.org-spacemacs-mode-line-new-version-lighter-warning {
}
.org-spacemacs-transient-state-title {
    /* spacemacs-transient-state-title-face */
    color: #d75fd7;
    font-weight: bold;
}
.org-speedbar-button {
    /* speedbar-button-face */
    color: #00cd00;
}
.org-speedbar-directory {
    /* speedbar-directory-face */
    color: #add8e6;
}
.org-speedbar-file {
    /* speedbar-file-face */
    color: #00ffff;
}
.org-speedbar-highlight {
    /* speedbar-highlight-face */
    background-color: #2e8b57;
}
.org-speedbar-selected {
    /* speedbar-selected-face */
    color: #ff0000;
    text-decoration: underline;
}
.org-speedbar-separator {
    /* speedbar-separator-face */
    color: #ffffff;
    background-color: #0000ff;
    text-decoration: overline;
}
.org-speedbar-tag {
    /* speedbar-tag-face */
    color: #ffff00;
}
.org-string {
    /* font-lock-string-face */
    color: #2aa198;
}
.org-subscript {
}
.org-success {
    /* success */
    color: #86dc2f;
}
.org-superscript {
}
.org-symbol-overlay-default {
    /* symbol-overlay-default-face */
    color: #b2b2b2;
    background-color: #444444;
}
.org-symbol-overlay-face-1 {
    /* symbol-overlay-face-1 */
    color: #000000;
    background-color: #1e90ff;
}
.org-symbol-overlay-face-2 {
    /* symbol-overlay-face-2 */
    color: #000000;
    background-color: #ff69b4;
}
.org-symbol-overlay-face-3 {
    /* symbol-overlay-face-3 */
    color: #000000;
    background-color: #ffff00;
}
.org-symbol-overlay-face-4 {
    /* symbol-overlay-face-4 */
    color: #000000;
    background-color: #da70d6;
}
.org-symbol-overlay-face-5 {
    /* symbol-overlay-face-5 */
    color: #000000;
    background-color: #ff0000;
}
.org-symbol-overlay-face-6 {
    /* symbol-overlay-face-6 */
    color: #000000;
    background-color: #fa8072;
}
.org-symbol-overlay-face-7 {
    /* symbol-overlay-face-7 */
    color: #000000;
    background-color: #00ff7f;
}
.org-symbol-overlay-face-8 {
    /* symbol-overlay-face-8 */
    color: #000000;
    background-color: #40e0d0;
}
.org-table-cell {
    /* table-cell */
    color: #e5e5e5;
    background-color: #0000ff;
}
.org-term {
    /* term */
    color: #b2b2b2;
    background-color: #262626;
}
.org-term-bold {
    /* term-bold */
    font-weight: bold;
}
.org-term-color-black {
    /* term-color-black */
    color: #080808;
}
.org-term-color-blue {
    /* term-color-blue */
    color: #268bd2;
}
.org-term-color-cyan {
    /* term-color-cyan */
    color: #00ffff;
}
.org-term-color-green {
    /* term-color-green */
    color: #67b11d;
}
.org-term-color-magenta {
    /* term-color-magenta */
    color: #af00df;
}
.org-term-color-red {
    /* term-color-red */
    color: #d70000;
}
.org-term-color-white {
    /* term-color-white */
    color: #b2b2b2;
}
.org-term-color-yellow {
    /* term-color-yellow */
    color: #875f00;
}
.org-term-underline {
    /* term-underline */
    text-decoration: underline;
}
.org-tex-math {
    /* tex-math */
    color: #2aa198;
}
.org-tex-verbatim {
}
.org-tide-choice {
    /* tide-choice-face */
    color: #dc752f;
    background-color: #262626;
}
.org-tide-file {
    /* tide-file */
    color: #d75fd7;
    font-weight: bold;
}
.org-tide-hl-identifier {
    /* tide-hl-identifier-face */
    color: #875f00;
    background-color: #262626;
}
.org-tide-imenu-type {
    /* tide-imenu-type-face */
    color: #df005f;
    font-weight: bold;
}
.org-tide-line-number {
    /* tide-line-number */
    color: #268bd2;
    font-weight: bold;
}
.org-tide-match {
    /* tide-match */
    color: #86dc2f;
    background-color: #444444;
}
.org-tool-bar {
    /* tool-bar */
    color: #000000;
}
.org-tooltip {
    /* tooltip */
    color: #b2b2b2;
    background-color: #333333;
}
.org-trailing-whitespace {
    /* trailing-whitespace */
    background-color: #008787;
}
.org-transient-active-infix {
    /* transient-active-infix */
    background-color: #121212;
}
.org-transient-argument {
    /* transient-argument */
    color: #dc752f;
    background-color: #262626;
}
.org-transient-disabled-suffix {
    /* transient-disabled-suffix */
    color: #000000;
    background-color: #ff0000;
    font-weight: bold;
}
.org-transient-enabled-suffix {
    /* transient-enabled-suffix */
    color: #000000;
    background-color: #00ff00;
    font-weight: bold;
}
.org-transient-heading {
    /* transient-heading */
    color: #268bd2;
    font-weight: bold;
}
.org-transient-inactive-argument {
    /* transient-inactive-argument */
    color: #585858;
}
.org-transient-inactive-value {
    /* transient-inactive-value */
    color: #585858;
}
.org-transient-key {
    /* transient-key */
    color: #268bd2;
}
.org-transient-mismatched-key {
    /* transient-mismatched-key */
    text-decoration: underline;
}
.org-transient-nonstandard-key {
    /* transient-nonstandard-key */
    text-decoration: underline;
}
.org-transient-separator {
    /* transient-separator */
    background-color: #4d4d4d;
}
.org-transient-unreachable {
    /* transient-unreachable */
    color: #585858;
}
.org-transient-unreachable-key {
    /* transient-unreachable-key */
    color: #585858;
}
.org-transient-value {
    /* transient-value */
    color: #2aa198;
}
.org-tty-menu-disabled {
    /* tty-menu-disabled-face */
    color: #d3d3d3;
    background-color: #0000ff;
}
.org-tty-menu-enabled {
    /* tty-menu-enabled-face */
    color: #ffff00;
    background-color: #0000ff;
    font-weight: bold;
}
.org-tty-menu-selected {
    /* tty-menu-selected-face */
    background-color: #ff0000;
}
.org-type {
    /* font-lock-type-face */
    color: #df005f;
    font-weight: bold;
}
.org-typescript-jsdoc-tag {
    /* typescript-jsdoc-tag */
    color: #708090;
}
.org-typescript-jsdoc-type {
    /* typescript-jsdoc-type */
    color: #4682b4;
}
.org-typescript-jsdoc-value {
    /* typescript-jsdoc-value */
    color: #8b7500;
}
.org-underline {
    /* underline */
    text-decoration: underline;
}
.org-undo-tree-visualizer-active-branch {
    /* undo-tree-visualizer-active-branch-face */
    color: #ffffff;
    font-weight: bold;
}
.org-undo-tree-visualizer-current {
    /* undo-tree-visualizer-current-face */
    color: #268bd2;
}
.org-undo-tree-visualizer-default {
    /* undo-tree-visualizer-default-face */
    color: #b2b2b2;
}
.org-undo-tree-visualizer-register {
    /* undo-tree-visualizer-register-face */
    color: #d75fd7;
}
.org-undo-tree-visualizer-unmodified {
    /* undo-tree-visualizer-unmodified-face */
    color: #8787d7;
}
.org-variable-name {
    /* font-lock-variable-name-face */
    color: #8787d7;
}
.org-variable-pitch {
}
.org-vc-conflict-state {
}
.org-vc-edited-state {
}
.org-vc-locally-added-state {
}
.org-vc-locked-state {
}
.org-vc-missing-state {
}
.org-vc-needs-update-state {
}
.org-vc-removed-state {
}
.org-vc-state-base {
}
.org-vc-up-to-date-state {
}
.org-vertical-border {
    /* vertical-border */
    color: #111111;
}
.org-warning {
    /* warning */
    color: #dc752f;
}
.org-warning-1 {
    /* font-lock-warning-face */
    color: #dc752f;
    background-color: #262626;
}
.org-web-mode-annotation {
    /* web-mode-annotation-face */
    color: #008787;
    background-color: #262626;
}
.org-web-mode-annotation-html {
    /* web-mode-annotation-html-face */
    color: #008787;
    background-color: #262626;
    font-style: italic;
}
.org-web-mode-annotation-tag {
    /* web-mode-annotation-tag-face */
    color: #008787;
    background-color: #262626;
    text-decoration: underline;
}
.org-web-mode-annotation-type {
    /* web-mode-annotation-type-face */
    color: #008787;
    background-color: #262626;
    font-weight: bold;
}
.org-web-mode-annotation-value {
    /* web-mode-annotation-value-face */
    color: #008787;
    background-color: #262626;
    font-style: italic;
}
.org-web-mode-block {
    /* web-mode-block-face */
    background-color: #000000;
}
.org-web-mode-block-attr-name {
    /* web-mode-block-attr-name-face */
    color: #8fbc8f;
}
.org-web-mode-block-attr-value {
    /* web-mode-block-attr-value-face */
    color: #5f9ea0;
}
.org-web-mode-block-comment {
    /* web-mode-block-comment-face */
    color: #008787;
    background-color: #262626;
}
.org-web-mode-block-control {
    /* web-mode-block-control-face */
    color: #d75fd7;
}
.org-web-mode-block-delimiter {
    /* web-mode-block-delimiter-face */
    color: #d75fd7;
}
.org-web-mode-block-string {
    /* web-mode-block-string-face */
    color: #2aa198;
}
.org-web-mode-bold {
    /* web-mode-bold-face */
    font-weight: bold;
}
.org-web-mode-builtin {
    /* web-mode-builtin-face */
    color: #268bd2;
}
.org-web-mode-comment {
    /* web-mode-comment-face */
    color: #008787;
    background-color: #262626;
}
.org-web-mode-comment-keyword {
    /* web-mode-comment-keyword-face */
    font-weight: bold;
}
.org-web-mode-constant {
    /* web-mode-constant-face */
    color: #d75fd7;
}
.org-web-mode-css-at-rule {
    /* web-mode-css-at-rule-face */
    color: #d75fd7;
}
.org-web-mode-css-color {
    /* web-mode-css-color-face */
    color: #268bd2;
}
.org-web-mode-css-comment {
    /* web-mode-css-comment-face */
    color: #008787;
    background-color: #262626;
}
.org-web-mode-css-function {
    /* web-mode-css-function-face */
    color: #268bd2;
}
.org-web-mode-css-priority {
    /* web-mode-css-priority-face */
    color: #268bd2;
}
.org-web-mode-css-property-name {
    /* web-mode-css-property-name-face */
    color: #8787d7;
}
.org-web-mode-css-pseudo-class {
    /* web-mode-css-pseudo-class-face */
    color: #268bd2;
}
.org-web-mode-css-selector {
    /* web-mode-css-selector-face */
    color: #268bd2;
    font-weight: bold;
}
.org-web-mode-css-string {
    /* web-mode-css-string-face */
    color: #2aa198;
}
.org-web-mode-css-variable {
    /* web-mode-css-variable-face */
    color: #8787d7;
    font-style: italic;
}
.org-web-mode-current-column-highlight {
    /* web-mode-current-column-highlight-face */
    background-color: #3e3c36;
}
.org-web-mode-current-element-highlight {
    /* web-mode-current-element-highlight-face */
    background-color: #121212;
}
.org-web-mode-doctype {
    /* web-mode-doctype-face */
    color: #008787;
    background-color: #262626;
}
.org-web-mode-error {
    /* web-mode-error-face */
    background-color: #ff0000;
}
.org-web-mode-filter {
    /* web-mode-filter-face */
    color: #d75fd7;
    font-weight: bold;
}
.org-web-mode-folded {
    /* web-mode-folded-face */
    text-decoration: underline;
}
.org-web-mode-function-call {
    /* web-mode-function-call-face */
    color: #d75fd7;
    font-weight: bold;
}
.org-web-mode-function-name {
    /* web-mode-function-name-face */
    color: #d75fd7;
    font-weight: bold;
}
.org-web-mode-html-attr-custom {
    /* web-mode-html-attr-custom-face */
    color: #d75fd7;
}
.org-web-mode-html-attr-engine {
    /* web-mode-html-attr-engine-face */
    color: #d75fd7;
}
.org-web-mode-html-attr-equal {
    /* web-mode-html-attr-equal-face */
    color: #d75fd7;
}
.org-web-mode-html-attr-name {
    /* web-mode-html-attr-name-face */
    color: #d75fd7;
}
.org-web-mode-html-attr-value {
    /* web-mode-html-attr-value-face */
    color: #268bd2;
}
.org-web-mode-html-entity {
    /* web-mode-html-entity-face */
    font-style: italic;
}
.org-web-mode-html-tag {
    /* web-mode-html-tag-face */
    color: #268bd2;
}
.org-web-mode-html-tag-bracket {
    /* web-mode-html-tag-bracket-face */
    color: #cdc9c9;
}
.org-web-mode-html-tag-custom {
    /* web-mode-html-tag-custom-face */
    color: #268bd2;
}
.org-web-mode-html-tag-namespaced {
    /* web-mode-html-tag-namespaced-face */
    color: #d75fd7;
}
.org-web-mode-html-tag-unclosed {
    /* web-mode-html-tag-unclosed-face */
    color: #268bd2;
    text-decoration: underline;
}
.org-web-mode-inlay {
    /* web-mode-inlay-face */
    background-color: #000000;
}
.org-web-mode-italic {
    /* web-mode-italic-face */
    font-style: italic;
}
.org-web-mode-javascript-comment {
    /* web-mode-javascript-comment-face */
    color: #008787;
    background-color: #262626;
}
.org-web-mode-javascript-string {
    /* web-mode-javascript-string-face */
    color: #2aa198;
}
.org-web-mode-json-comment {
    /* web-mode-json-comment-face */
    color: #008787;
    background-color: #262626;
}
.org-web-mode-json-context {
    /* web-mode-json-context-face */
    color: #cd69c9;
}
.org-web-mode-json-key {
    /* web-mode-json-key-face */
    color: #dda0dd;
}
.org-web-mode-json-string {
    /* web-mode-json-string-face */
    color: #2aa198;
}
.org-web-mode-jsx-depth-1 {
    /* web-mode-jsx-depth-1-face */
    background-color: #000053;
}
.org-web-mode-jsx-depth-2 {
    /* web-mode-jsx-depth-2-face */
    background-color: #001970;
}
.org-web-mode-jsx-depth-3 {
    /* web-mode-jsx-depth-3-face */
    background-color: #002984;
}
.org-web-mode-jsx-depth-4 {
    /* web-mode-jsx-depth-4-face */
    background-color: #49599a;
}
.org-web-mode-jsx-depth-5 {
    /* web-mode-jsx-depth-5-face */
    background-color: #9499b7;
}
.org-web-mode-keyword {
    /* web-mode-keyword-face */
    color: #268bd2;
}
.org-web-mode-param-name {
    /* web-mode-param-name-face */
    color: #cdc9c9;
}
.org-web-mode-part {
    /* web-mode-part-face */
    background-color: #000000;
}
.org-web-mode-part-comment {
    /* web-mode-part-comment-face */
    color: #008787;
    background-color: #262626;
}
.org-web-mode-part-string {
    /* web-mode-part-string-face */
    color: #2aa198;
}
.org-web-mode-preprocessor {
    /* web-mode-preprocessor-face */
    color: #d75fd7;
}
.org-web-mode-script {
    /* web-mode-script-face */
    background-color: #000000;
}
.org-web-mode-sql-keyword {
    /* web-mode-sql-keyword-face */
    font-weight: bold;
    font-style: italic;
}
.org-web-mode-string {
    /* web-mode-string-face */
    color: #2aa198;
}
.org-web-mode-style {
    /* web-mode-style-face */
    background-color: #000000;
}
.org-web-mode-symbol {
    /* web-mode-symbol-face */
    color: #df005f;
}
.org-web-mode-type {
    /* web-mode-type-face */
    color: #df005f;
    font-weight: bold;
}
.org-web-mode-underline {
    /* web-mode-underline-face */
    text-decoration: underline;
}
.org-web-mode-variable-name {
    /* web-mode-variable-name-face */
    color: #8787d7;
}
.org-web-mode-warning {
    /* web-mode-warning-face */
    color: #dc752f;
    background-color: #262626;
}
.org-web-mode-whitespace {
    /* web-mode-whitespace-face */
    background-color: #68228b;
}
.org-which-func {
    /* which-func */
    color: #d75fd7;
}
.org-which-key-command-description {
    /* which-key-command-description-face */
    color: #b2b2b2;
}
.org-which-key-docstring {
    /* which-key-docstring-face */
    color: #2aa198;
}
.org-which-key-group-description {
    /* which-key-group-description-face */
    color: #268bd2;
}
.org-which-key-highlighted-command {
    /* which-key-highlighted-command-face */
    color: #b2b2b2;
    text-decoration: underline;
}
.org-which-key-key {
    /* which-key-key-face */
    color: #d75fd7;
    font-weight: bold;
}
.org-which-key-local-map-description {
    /* which-key-local-map-description-face */
    color: #b2b2b2;
}
.org-which-key-note {
    /* which-key-note-face */
    color: #2aa198;
}
.org-which-key-separator {
    /* which-key-separator-face */
    color: #2aa198;
}
.org-which-key-special-key {
    /* which-key-special-key-face */
    color: #262626;
    background-color: #d75fd7;
}
.org-whitespace-big-indent {
    /* whitespace-big-indent */
    color: #b22222;
    background-color: #ff0000;
}
.org-whitespace-empty {
    /* whitespace-empty */
    color: #875f00;
}
.org-whitespace-hspace {
    /* whitespace-hspace */
    color: #a9a9a9;
    background-color: #3d3d3d;
}
.org-whitespace-indentation {
    /* whitespace-indentation */
    color: #dc752f;
}
.org-whitespace-line {
    /* whitespace-line */
    color: #d75fd7;
}
.org-whitespace-newline {
    /* whitespace-newline */
    color: #d75fd7;
}
.org-whitespace-space {
    /* whitespace-space */
    color: #dc752f;
}
.org-whitespace-space-after-tab {
    /* whitespace-space-after-tab */
    color: #875f00;
}
.org-whitespace-space-before-tab {
    /* whitespace-space-before-tab */
    color: #875f00;
}
.org-whitespace-tab {
    /* whitespace-tab */
    color: #444444;
}
.org-whitespace-trailing {
    /* whitespace-trailing */
    color: #dc752f;
    background-color: #e0211d;
}
.org-widget-button {
    /* widget-button */
    font-weight: bold;
}
.org-widget-button-pressed {
    /* widget-button-pressed */
    color: #ff0000;
}
.org-widget-documentation {
    /* widget-documentation */
    color: #32cd32;
}
.org-widget-field {
    /* widget-field */
    color: #000000;
    background-color: #cdcd00;
}
.org-widget-inactive {
    /* widget-inactive */
    color: #585858;
}
.org-widget-single-line-field {
    /* widget-single-line-field */
    color: #000000;
    background-color: #00cd00;
}
.org-window-divider {
    /* window-divider */
    color: #999999;
}
.org-window-divider-first-pixel {
    /* window-divider-first-pixel */
    color: #cccccc;
}
.org-window-divider-last-pixel {
    /* window-divider-last-pixel */
    color: #666666;
}
.org-winum {
}
.org-yas--field-debug {
}

a {
    color: inherit;
    background-color: inherit;
    font: inherit;
    text-decoration: inherit;
}
a:hover {
    text-decoration: underline;
}
