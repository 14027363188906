@import "https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,600;1,400;1,600&display=swap";
:not(pre) > code {
  color: #333;
  background-clip: padding-box;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: auto 1px;
  padding: 2px 5px;
}

.org-src-container {
  border: 1px solid #ccc;
  margin: 1em auto;
  padding: .1em .5em;
  position: relative;
  box-shadow: 3px 3px 3px #eee;
}

.org-src-container > pre {
  overflow: auto;
}

.org-src-container > pre:before {
  color: #fff;
  background-color: #b3b3b3;
  border: 0;
  border-bottom-left-radius: 8px;
  padding: 0 .5em;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.org-src-container > pre.src-sh:before {
  content: "sh";
}

.org-src-container > pre.src-bash:before {
  content: "bash";
}

.org-src-container > pre.src-emacs-lisp:before {
  content: "Emacs Lisp";
}

.org-src-container > pre.src-R:before {
  content: "R";
}

.org-src-container > pre.src-org:before {
  content: "Org";
}

.org-src-container > pre.src-cpp:before {
  content: "C++";
}

.org-src-container > pre.src-c:before {
  content: "C";
}

.org-src-container > pre.src-html:before {
  content: "HTML";
}

.org-src-container > pre.src-js:before, .org-src-container > pre.src-javascript:before {
  content: "Javascript";
}

.org-src-container > pre.src-abc:before {
  content: "ABC";
}

.org-src-container > pre.src-asymptote:before {
  content: "Asymptote";
}

.org-src-container > pre.src-awk:before {
  content: "Awk";
}

.org-src-container > pre.src-C:before {
  content: "C";
}

.org-src-container > pre.src-calc:before {
  content: "Calc";
}

.org-src-container > pre.src-clojure:before {
  content: "Clojure";
}

.org-src-container > pre.src-comint:before {
  content: "comint";
}

.org-src-container > pre.src-css:before {
  content: "CSS";
}

.org-src-container > pre.src-D:before {
  content: "D";
}

.org-src-container > pre.src-ditaa:before {
  content: "Ditaa";
}

.org-src-container > pre.src-dot:before {
  content: "Dot";
}

.org-src-container > pre.src-ebnf:before {
  content: "ebnf";
}

.org-src-container > pre.src-forth:before {
  content: "Forth";
}

.org-src-container > pre.src-F90:before {
  content: "Fortran";
}

.org-src-container > pre.src-gnuplot:before {
  content: "Gnuplot";
}

.org-src-container > pre.src-haskell:before {
  content: "Haskell";
}

.org-src-container > pre.src-io:before {
  content: "Io";
}

.org-src-container > pre.src-java:before {
  content: "Java";
}

.org-src-container > pre.src-latex:before {
  content: "LaTeX";
}

.org-src-container > pre.src-ledger:before {
  content: "Ledger";
}

.org-src-container > pre.src-ly:before {
  content: "Lilypond";
}

.org-src-container > pre.src-lisp:before {
  content: "Lisp";
}

.org-src-container > pre.src-makefile:before {
  content: "Make";
}

.org-src-container > pre.src-matlab:before {
  content: "Matlab";
}

.org-src-container > pre.src-max:before {
  content: "Maxima";
}

.org-src-container > pre.src-mscgen:before {
  content: "Mscgen";
}

.org-src-container > pre.src-Caml:before {
  content: "Objective";
}

.org-src-container > pre.src-octave:before {
  content: "Octave";
}

.org-src-container > pre.src-org:before {
  content: "Org";
}

.org-src-container > pre.src-perl:before {
  content: "Perl";
}

.org-src-container > pre.src-picolisp:before {
  content: "Picolisp";
}

.org-src-container > pre.src-plantuml:before {
  content: "PlantUML";
}

.org-src-container > pre.src-python:before {
  content: "Python";
}

.org-src-container > pre.src-ruby:before {
  content: "Ruby";
}

.org-src-container > pre.src-sass:before {
  content: "Sass";
}

.org-src-container > pre.src-scala:before {
  content: "Scala";
}

.org-src-container > pre.src-scheme:before {
  content: "Scheme";
}

.org-src-container > pre.src-screen:before {
  content: "Screen";
}

.org-src-container > pre.src-sed:before {
  content: "Sed";
}

.org-src-container > pre.src-shell:before {
  content: "shell";
}

.org-src-container > pre.src-shen:before {
  content: "Shen";
}

.org-src-container > pre.src-sql:before {
  content: "SQL";
}

.org-src-container > pre.src-sqlite:before {
  content: "SQLite";
}

.org-src-container > pre.src-stan:before {
  content: "Stan";
}

.org-src-container > pre.src-vala:before {
  content: "Vala";
}

.org-src-container > pre.src-axiom:before {
  content: "Axiom";
}

.org-src-container > pre.src-browser:before {
  content: "HTML";
}

.org-src-container > pre.src-cypher:before {
  content: "Neo4j";
}

.org-src-container > pre.src-elixir:before {
  content: "Elixir";
}

.org-src-container > pre.src-request:before {
  content: "http";
}

.org-src-container > pre.src-ipython:before {
  content: "iPython";
}

.org-src-container > pre.src-kotlin:before {
  content: "Kotlin";
}

.org-src-container > pre.src-Flavored Erlang lfe:before {
  content: "Lisp";
}

.org-src-container > pre.src-mongo:before {
  content: "MongoDB";
}

.org-src-container > pre.src-prolog:before {
  content: "Prolog";
}

.org-src-container > pre.src-rec:before {
  content: "rec";
}

.org-src-container > pre.src-ML sml:before {
  content: "Standard";
}

.org-src-container > pre.src-Translate translate:before {
  content: "Google";
}

.org-src-container > pre.src-typescript:before {
  content: "Typescript";
}

.org-src-container > pre.src-rust:before {
  content: "Rust";
}

body {
  width: 95%;
  color: #333;
  margin: 2% auto;
  font-family: Georgia, serif;
  font-size: 14px;
  line-height: 1.4em;
}

@media screen and (min-width: 600px) {
  body {
    font-size: 18px;
  }
}

@media screen and (min-width: 910px) {
  body {
    width: 900px;
  }
}

::selection {
  background: #d6edff;
}

p {
  margin: 1em auto;
}

ol, ul, dl {
  margin: 0 auto;
}

.title {
  text-align: center;
  color: #000;
  margin: .8em auto;
}

.subtitle {
  text-align: center;
  margin: 1em auto;
  font-size: 1.1em;
  font-weight: bold;
  line-height: 1.4;
}

.abstract {
  width: 80%;
  margin: auto;
  font-style: italic;
}

.abstract p:last-of-type:before {
  content: "    ";
  white-space: pre;
}

.status {
  margin: 2em auto;
  font-size: 90%;
}

[class^="section-number-"] {
  margin-right: .5em;
}

[id^="orgheadline"] {
  clear: both;
}

#footnotes {
  font-size: 90%;
}

.footpara {
  margin: .2em auto;
  display: inline;
}

.footdef {
  margin-bottom: 1em;
}

.footdef sup {
  padding-right: .5em;
}

.content a {
  color: #527d9a;
  text-decoration: underline dotted;
}

.content a:hover {
  color: #035;
}

figure {
  text-align: center;
  margin: 1em auto;
  padding: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.MathJax_Display {
  width: 90% !important;
  margin: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
  color: #a5573e;
  font-family: Helvetica, sans-serif;
  line-height: 1em;
}

h1, h2, h3 {
  line-height: 1.4em;
}

h4, h5, h6 {
  font-size: 1em;
}

@media screen and (min-width: 600px) {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.3em;
  }

  h1, h2, h3 {
    line-height: 1.4em;
  }

  h4, h5, h6 {
    font-size: 1.1em;
  }
}

dt {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-top: 2px solid;
  border-bottom: 2px solid;
  margin: 1em auto;
}

thead {
  border-bottom: 2px solid;
}

table td + td, table th + th {
  border-left: 1px solid gray;
}

table tr {
  border-top: 1px solid #d3d3d3;
}

td, th {
  vertical-align: middle;
  padding: .3em .6em;
}

caption.t-above {
  caption-side: top;
}

caption.t-bottom {
  caption-side: bottom;
}

caption {
  margin-bottom: .3em;
}

figcaption {
  margin-top: .3em;
}

th.org-right, th.org-left, th.org-center {
  text-align: center;
}

td.org-right {
  text-align: right;
}

td.org-left {
  text-align: left;
}

td.org-center {
  text-align: center;
}

blockquote {
  overflow-wrap: break-word;
  border-left: 3px solid #ccc;
  margin: 1em 2em;
  padding-left: 1em;
}

kbd {
  background-color: #f7f7f7;
  margin: 0 .1em;
  padding: .1em .6em;
  font-size: 80%;
}

.todo {
  color: #fff;
  background-color: red;
  background-clip: padding-box;
  border-radius: 3px;
  padding: .1em .3em;
  font-family: Lucida Console, monospace;
  font-size: 80%;
  line-height: 1;
}

.done {
  color: #fff;
  background-color: green;
  background-clip: padding-box;
  border-radius: 3px;
  padding: .1em .3em;
  font-family: Lucida Console, monospace;
  font-size: 80%;
  line-height: 1;
}

.priority {
  color: orange;
  font-family: Lucida Console, monospace;
}

#table-of-contents li {
  clear: both;
}

.tag {
  font-family: Lucida Console, monospace;
  font-size: .7em;
  font-weight: normal;
}

.tag span {
  float: right;
  color: #333;
  background-color: #eee;
  background-clip: padding-box;
  border: 1px solid #bbb;
  border-radius: 3px;
  margin-right: .5em;
  padding: .3em;
  line-height: 1;
}

.timestamp {
  color: #bebebe;
  font-size: 90%;
}

.timestamp-kwd {
  color: #5f9ea0;
}

.org-right {
  text-align: right;
  margin-left: auto;
  margin-right: 0;
}

.org-left {
  text-align: left;
  margin-left: 0;
  margin-right: auto;
}

.org-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.underline {
  text-decoration: underline;
}

#postamble p, #preamble p {
  margin: .2em;
  font-size: 90%;
}

p.verse {
  margin-left: 3%;
}

.inlinetask {
  background: #ffc;
  border: 2px solid gray;
  margin: 10px;
  padding: 10px;
}

#org-div-home-and-up {
  text-align: right;
  white-space: nowrap;
  font-size: 70%;
}

.linenr {
  font-size: 90%;
}

.code-highlighted {
  background-color: #ff0;
}

#bibliography {
  font-size: 90%;
}

#bibliography table {
  width: 100%;
}

.creator {
  display: block;
}

@media screen and (min-width: 600px) {
  .creator {
    float: right;
    display: inline;
  }
}

.org-src-container {
  color: #b2b2b2;
  background-color: #262626;
}

.org-ahs {
  background-color: #444;
}

.org-ahs-definition {
  color: #ffe4b5;
  background-color: #5f9ea0;
  text-decoration: underline;
}

.org-ahs-edit-mode {
  color: #fff;
  background-color: #cd5b45;
}

.org-ahs-plugin-bod {
  color: #000;
  background-color: #1e90ff;
}

.org-ahs-plugin-defalt {
  color: #000;
  background-color: orange;
}

.org-ahs-plugin-whole-buffer {
  color: #262626;
  background-color: #86dc2f;
}

.org-ahs-warning {
  color: red;
  font-weight: bold;
}

.org-anzu-match-1 {
  color: #000;
  background-color: #32cd32;
}

.org-anzu-match-2 {
  color: #000;
  background-color: #ff0;
}

.org-anzu-match-3 {
  color: #000;
  background-color: #7fffd4;
}

.org-anzu-mode-line, .org-anzu-mode-line-no-match {
  color: #875f00;
  font-weight: bold;
}

.org-anzu-replace-highlight {
  color: #262626;
  background-color: #86dc2f;
}

.org-anzu-replace-to {
  color: #ff0;
}

.org-apropos-keybinding {
  text-decoration: underline;
}

.org-apropos-property {
  color: #268bd2;
}

.org-apropos-symbol, .org-bold {
  font-weight: bold;
}

.org-bold-italic {
  font-style: italic;
  font-weight: bold;
}

.org-bookmark-menu-bookmark {
  font-weight: bold;
}

.org-bookmark-menu-heading {
  color: #df005f;
  font-weight: bold;
}

.org-buffer-menu-buffer {
  font-weight: bold;
}

.org-builtin {
  color: #268bd2;
}

.org-button {
  color: #008787;
  text-decoration: underline;
}

.org-c-annotation {
  color: #d75fd7;
}

.org-calendar-month-header {
  color: #d75fd7;
  font-weight: bold;
}

.org-calendar-today {
  text-decoration: underline;
}

.org-calendar-weekday-header {
  color: #d75fd7;
}

.org-calendar-weekend-header, .org-change-log-acknowledgment {
  color: #008787;
  background-color: #262626;
}

.org-change-log-conditionals {
  color: #8787d7;
}

.org-change-log-date {
  color: #2aa198;
}

.org-change-log-email {
  color: #8787d7;
}

.org-change-log-file {
  color: #d75fd7;
  font-weight: bold;
}

.org-change-log-function {
  color: #8787d7;
}

.org-change-log-list {
  color: #268bd2;
  font-weight: bold;
}

.org-change-log-name {
  color: #d75fd7;
}

.org-comint-highlight-input {
  font-weight: bold;
}

.org-comint-highlight-prompt {
  color: #268bd2;
  font-weight: bold;
}

.org-comment, .org-comment-delimiter {
  color: #008787;
  background-color: #262626;
}

.org-company-echo-common {
  color: #262626;
  background-color: #b2b2b2;
}

.org-company-preview {
  color: #888;
  background-color: #444;
}

.org-company-preview-common {
  color: #b2b2b2;
  background-color: #444;
}

.org-company-preview-search {
  color: #86dc2f;
  background-color: #444;
}

.org-company-scrollbar-bg {
  background-color: #1c1c1c;
}

.org-company-scrollbar-fg, .org-company-template-field {
  background-color: #444;
}

.org-company-tooltip {
  color: #888;
  background-color: #444;
}

.org-company-tooltip-annotation, .org-company-tooltip-annotation-selection {
  color: #df005f;
}

.org-company-tooltip-common {
  color: #268bd2;
  background-color: #444;
}

.org-company-tooltip-common-selection {
  color: #b2b2b2;
}

.org-company-tooltip-mouse {
  color: #b2b2b2;
  background-color: #444;
}

.org-company-tooltip-search {
  color: #86dc2f;
  background-color: #444;
}

.org-company-tooltip-search-selection {
  color: #b2b2b2;
  background-color: #444;
}

.org-company-tooltip-selection {
  color: #b2b2b2;
  background-color: #333;
}

.org-company-web-doc-attribute {
  color: #df005f;
  font-weight: bold;
}

.org-company-web-doc-base {
  color: #00bfff;
}

.org-company-web-doc-header-1, .org-company-web-doc-important {
  color: #00bfff;
  font-weight: bold;
}

.org-company-web-doc-tag {
  color: #d75fd7;
  font-weight: bold;
}

.org-company-web-doc-text-1 {
  color: #00bfff;
}

.org-company-web-doc-warning {
  color: #dc752f;
  background-color: #262626;
}

.org-compilation-column-number {
  color: #af875f;
}

.org-compilation-error {
  color: #e0211d;
}

.org-compilation-info {
  color: #86dc2f;
}

.org-compilation-line-number {
  color: #268bd2;
  font-weight: bold;
}

.org-compilation-mode-line-exit {
  color: #228b22;
  font-weight: bold;
}

.org-compilation-mode-line-fail {
  color: red;
  font-weight: bold;
}

.org-compilation-mode-line-run, .org-compilation-warning {
  color: #dc752f;
}

.org-completions-annotations {
  text-decoration: underline;
}

.org-completions-first-difference {
  font-weight: bold;
}

.org-constant {
  color: #d75fd7;
}

.org-counsel-variable-documentation {
  color: #008787;
  background-color: #262626;
}

.org-css-property {
  color: #268bd2;
  font-weight: bold;
}

.org-css-selector {
  color: #d75fd7;
  font-weight: bold;
}

.org-cursor {
  background-color: #d0d0d0;
}

.org-custom-button {
  color: #b2b2b2;
  background-color: #1c1c1c;
}

.org-custom-button-pressed-unraised {
  color: violet;
  text-decoration: underline;
}

.org-custom-button-unraised {
  text-decoration: underline;
}

.org-custom-changed {
  color: #fff;
  background-color: #00f;
}

.org-custom-comment {
  color: #000;
  background-color: #cdcd00;
}

.org-custom-comment-tag {
  color: #ccc;
}

.org-custom-face-tag {
  color: #add8e6;
  font-weight: bold;
}

.org-custom-group-subtitle {
  font-weight: bold;
}

.org-custom-group-tag {
  color: #add8e6;
  font-size: 120%;
  font-weight: bold;
}

.org-custom-group-tag-1 {
  color: pink;
  font-size: 120%;
  font-weight: bold;
}

.org-custom-invalid {
  color: #ff0;
  background-color: red;
}

.org-custom-link {
  color: #008787;
  text-decoration: underline;
}

.org-custom-modified {
  color: #fff;
  background-color: #00f;
}

.org-custom-rogue {
  color: pink;
  background-color: #000;
}

.org-custom-saved {
  text-decoration: underline;
}

.org-custom-set {
  color: #00f;
  background-color: #fff;
}

.org-custom-state {
  color: #32cd32;
}

.org-custom-themed {
  color: #fff;
  background-color: #00f;
}

.org-custom-variable-button {
  font-weight: bold;
  text-decoration: underline;
}

.org-custom-variable-tag {
  color: #add8e6;
  font-weight: bold;
}

.org-custom-visibility {
  color: #008787;
  font-size: 80%;
  text-decoration: underline;
}

.org-diary {
  color: #ff0;
}

.org-diff-added {
  color: #67b11d;
}

.org-diff-changed {
  color: #268bd2;
}

.org-diff-context {
  color: #ddd;
}

.org-diff-file-header {
  color: #b2b2b2;
  background-color: #333;
}

.org-diff-function, .org-diff-header, .org-diff-hunk-header {
  color: #d75fd7;
  background-color: #333;
}

.org-diff-index {
  color: #b2b2b2;
  background-color: #333;
}

.org-diff-indicator-added {
  color: #67b11d;
}

.org-diff-indicator-changed {
  color: #268bd2;
}

.org-diff-indicator-removed {
  color: #d70000;
}

.org-diff-nonexistent {
  color: #b2b2b2;
  background-color: #333;
}

.org-diff-refine-added {
  color: #262626;
  background-color: #67b11d;
}

.org-diff-refine-changed {
  color: #262626;
  background-color: #268bd2;
}

.org-diff-refine-removed {
  color: #262626;
  background-color: #d70000;
}

.org-diff-removed {
  color: #d70000;
}

.org-dired-directory {
  color: #268bd2;
  background-color: #262626;
  font-weight: bold;
}

.org-dired-flagged {
  color: #d70000;
}

.org-dired-header {
  color: #d75fd7;
  font-weight: bold;
}

.org-dired-ignored {
  color: #585858;
}

.org-dired-mark {
  color: #d75fd7;
  font-weight: bold;
}

.org-dired-marked {
  color: #af00df;
  font-weight: bold;
}

.org-dired-perm-write {
  color: #b2b2b2;
  text-decoration: underline;
}

.org-dired-symlink {
  color: #0ff;
  background-color: #262626;
  font-weight: bold;
}

.org-dired-warning {
  color: #dc752f;
}

.org-doc {
  color: #af875f;
}

.org-eldoc-highlight-function-argument {
  color: #86dc2f;
  font-weight: bold;
}

.org-emmet-preview-input {
  background-color: #121212;
}

.org-emmet-preview-output {
  color: #b2b2b2;
  background-color: #444;
}

.org-epa-field-body {
  color: #40e0d0;
  font-style: italic;
}

.org-epa-field-name {
  color: #afeeee;
  font-weight: bold;
}

.org-epa-mark {
  color: orange;
  font-weight: bold;
}

.org-epa-string {
  color: #ffffe0;
}

.org-epa-validity-disabled {
  font-style: italic;
}

.org-epa-validity-high {
  color: #afeeee;
  font-weight: bold;
}

.org-epa-validity-low {
  font-style: italic;
}

.org-epa-validity-medium {
  color: #afeeee;
  font-style: italic;
}

.org-error {
  color: #e0211d;
}

.org-escape-glyph {
  color: #0ff;
}

.org-eval-sexp-fu-flash {
  color: #262626;
  background-color: #86dc2f;
}

.org-eval-sexp-fu-flash-error {
  color: #262626;
  background-color: #e0211d;
}

.org-evil-ex-commands {
  font-style: italic;
  text-decoration: underline;
}

.org-evil-ex-info {
  color: red;
  font-style: italic;
}

.org-evil-ex-lazy-highlight {
  background-color: #262626;
}

.org-evil-ex-search {
  color: #262626;
  background-color: #86dc2f;
}

.org-evil-ex-substitute-matches {
  color: #d70000;
  background-color: #262626;
}

.org-evil-ex-substitute-replacement {
  color: #67b11d;
  background-color: #262626;
}

.org-eww-form-text {
  color: #fff;
  background-color: #505050;
}

.org-eww-form-textarea {
  color: #000;
  background-color: silver;
}

.org-eww-invalid-certificate {
  color: red;
  font-weight: bold;
}

.org-eww-valid-certificate {
  color: #228b22;
  font-weight: bold;
}

.org-eyebrowse-mode-line-active {
  font-weight: bold;
}

.org-ffap {
  color: #b2b2b2;
}

.org-file-name-shadow {
  color: #585858;
}

.org-flx-highlight {
  color: #d75fd7;
}

.org-flycheck-error {
  text-decoration: underline;
}

.org-flycheck-error-list-checker-name {
  color: #268bd2;
}

.org-flycheck-error-list-column-number {
  color: #d75fd7;
}

.org-flycheck-error-list-error {
  color: #e0211d;
}

.org-flycheck-error-list-filename {
  color: #8787d7;
}

.org-flycheck-error-list-highlight {
  color: #b2b2b2;
  background-color: #444;
}

.org-flycheck-error-list-id, .org-flycheck-error-list-id-with-explainer {
  color: #df005f;
  font-weight: bold;
}

.org-flycheck-error-list-info {
  color: #86dc2f;
}

.org-flycheck-error-list-line-number {
  color: #d75fd7;
}

.org-flycheck-error-list-warning {
  color: #dc752f;
}

.org-flycheck-fringe-error {
  color: #e0211d;
  font-weight: bold;
}

.org-flycheck-fringe-info {
  color: #268bd2;
  font-weight: bold;
}

.org-flycheck-fringe-warning {
  color: #dc752f;
  font-weight: bold;
}

.org-flycheck-info, .org-flycheck-warning, .org-flymake-error, .org-flymake-note, .org-flymake-warning, .org-flyspell-duplicate, .org-flyspell-incorrect {
  text-decoration: underline;
}

.org-fringe {
  color: #b2b2b2;
  background-color: #262626;
}

.org-function-name {
  color: #d75fd7;
  font-weight: bold;
}

.org-git-commit-comment-action {
  font-weight: bold;
}

.org-git-commit-comment-branch-local, .org-git-commit-comment-branch-remote, .org-git-commit-comment-detached {
  color: #8787d7;
}

.org-git-commit-comment-file {
  color: #2aa198;
}

.org-git-commit-comment-heading {
  color: #268bd2;
  font-weight: bold;
}

.org-git-commit-keyword {
  color: #2aa198;
}

.org-git-commit-known-pseudo-header {
  color: #268bd2;
  font-weight: bold;
}

.org-git-commit-nonempty-second-line, .org-git-commit-overlong-summary {
  color: #dc752f;
  background-color: #262626;
}

.org-git-commit-pseudo-header {
  color: #2aa198;
}

.org-git-commit-summary {
  color: #df005f;
  font-weight: bold;
}

.org-git-rebase-comment-hash {
  color: #8787d7;
  font-weight: bold;
}

.org-git-rebase-comment-heading {
  color: #268bd2;
  font-weight: bold;
}

.org-git-rebase-hash {
  color: #8787d7;
}

.org-git-rebase-killed-action {
  color: #008787;
  background-color: #262626;
  text-decoration: line-through;
}

.org-git-rebase-label {
  color: #ccc;
}

.org-glyphless-char {
  text-decoration: underline;
}

.org-gnus-group-mail-1 {
  color: #e1ffe1;
  font-weight: bold;
}

.org-gnus-group-mail-1-empty {
  color: #e1ffe1;
}

.org-gnus-group-mail-2 {
  color: #c1ffc1;
  font-weight: bold;
}

.org-gnus-group-mail-2-empty {
  color: #c1ffc1;
}

.org-gnus-group-mail-3 {
  color: #7fffd4;
  font-weight: bold;
}

.org-gnus-group-mail-3-empty {
  color: #7fffd4;
}

.org-gnus-group-mail-low {
  color: #76eec6;
  font-weight: bold;
}

.org-gnus-group-mail-low-empty {
  color: #76eec6;
}

.org-gnus-group-news-1 {
  color: #afeeee;
  font-weight: bold;
}

.org-gnus-group-news-1-empty {
  color: #afeeee;
}

.org-gnus-group-news-2 {
  color: #40e0d0;
  font-weight: bold;
}

.org-gnus-group-news-2-empty {
  color: #40e0d0;
}

.org-gnus-group-news-3, .org-gnus-group-news-4, .org-gnus-group-news-5, .org-gnus-group-news-6 {
  font-weight: bold;
}

.org-gnus-group-news-low {
  color: #00ced1;
  font-weight: bold;
}

.org-gnus-group-news-low-empty {
  color: #00ced1;
}

.org-gnus-splash {
  color: #ccc;
}

.org-gnus-summary-cancelled {
  color: #262626;
  background-color: #dc752f;
}

.org-gnus-summary-high-ancient {
  color: #87ceeb;
  font-weight: bold;
}

.org-gnus-summary-high-read {
  color: #98fb98;
  font-weight: bold;
}

.org-gnus-summary-high-ticked {
  color: pink;
  font-weight: bold;
}

.org-gnus-summary-high-undownloaded {
  color: #d3d3d3;
  font-weight: bold;
}

.org-gnus-summary-high-unread {
  font-weight: bold;
}

.org-gnus-summary-low-ancient {
  color: #87ceeb;
  font-style: italic;
}

.org-gnus-summary-low-read {
  color: #98fb98;
  font-style: italic;
}

.org-gnus-summary-low-ticked {
  color: pink;
  font-style: italic;
}

.org-gnus-summary-low-undownloaded {
  color: #d3d3d3;
  font-style: italic;
}

.org-gnus-summary-low-unread {
  font-style: italic;
}

.org-gnus-summary-normal-ancient {
  color: #87ceeb;
}

.org-gnus-summary-normal-read {
  color: #98fb98;
}

.org-gnus-summary-normal-ticked {
  color: pink;
}

.org-gnus-summary-normal-undownloaded {
  color: #d3d3d3;
}

.org-gnus-summary-selected {
  text-decoration: underline;
}

.org-header-line {
  background-color: #080808;
}

.org-header-line-highlight {
  color: #b2b2b2;
  background-color: #444;
}

.org-helm-action {
  text-decoration: underline;
}

.org-helm-bookmark-addressbook {
  color: tomato;
}

.org-helm-bookmark-directory {
  color: #268bd2;
  background-color: #262626;
  font-weight: bold;
}

.org-helm-bookmark-file {
  color: #b2b2b2;
}

.org-helm-bookmark-file-not-found {
  color: #6c7b8b;
}

.org-helm-bookmark-gnus, .org-helm-bookmark-info, .org-helm-bookmark-man, .org-helm-bookmark-w3m {
  color: #d75fd7;
}

.org-helm-buffer-archive {
  color: gold;
}

.org-helm-buffer-directory, .org-helm-buffer-file {
  color: #b2b2b2;
  background-color: #262626;
}

.org-helm-buffer-modified {
  color: #008787;
  background-color: #262626;
}

.org-helm-buffer-not-saved {
  color: #d75fd7;
  background-color: #262626;
}

.org-helm-buffer-process {
  color: #268bd2;
  background-color: #262626;
}

.org-helm-buffer-saved-out, .org-helm-buffer-size {
  color: #b2b2b2;
  background-color: #262626;
}

.org-helm-candidate-number, .org-helm-candidate-number-suspended {
  color: #268bd2;
  background-color: #262626;
  font-weight: bold;
}

.org-helm-delete-async-message {
  color: #ff0;
}

.org-helm-etags-file {
  color: #8b814c;
  text-decoration: underline;
}

.org-helm-ff-denied {
  color: red;
  background-color: #000;
}

.org-helm-ff-directory {
  color: #268bd2;
  background-color: #262626;
  font-weight: bold;
}

.org-helm-ff-dirs {
  color: #d75fd7;
  font-weight: bold;
}

.org-helm-ff-dotted-directory {
  color: #268bd2;
  background-color: #262626;
  font-weight: bold;
}

.org-helm-ff-dotted-symlink-directory {
  color: #0ff;
  background-color: #262626;
  font-weight: bold;
}

.org-helm-ff-executable {
  color: #86dc2f;
  background-color: #262626;
}

.org-helm-ff-file {
  color: #b2b2b2;
  background-color: #262626;
}

.org-helm-ff-invalid-symlink {
  color: #d70000;
  background-color: #262626;
  font-weight: bold;
}

.org-helm-ff-pipe {
  color: #ff0;
  background-color: #000;
}

.org-helm-ff-prefix {
  color: #262626;
  background-color: #268bd2;
}

.org-helm-ff-socket {
  color: #ff1493;
}

.org-helm-ff-suid {
  color: #fff;
  background-color: red;
}

.org-helm-ff-symlink {
  color: #0ff;
  background-color: #262626;
  font-weight: bold;
}

.org-helm-ff-truename {
  color: #2aa198;
}

.org-helm-grep-cmd-line, .org-helm-grep-file, .org-helm-grep-finish {
  color: #b2b2b2;
  background-color: #262626;
}

.org-helm-grep-lineno {
  color: #df005f;
  background-color: #262626;
  font-weight: bold;
}

.org-helm-grep-match {
  color: #268bd2;
  background-color: #262626;
}

.org-helm-header {
  color: #b2b2b2;
  background-color: #262626;
}

.org-helm-header-line-left-margin {
  color: #268bd2;
}

.org-helm-helper {
  color: #b2b2b2;
  background-color: #262626;
}

.org-helm-history-deleted {
  color: #d70000;
  background-color: #262626;
  font-weight: bold;
}

.org-helm-history-remote {
  color: #ff6a6a;
}

.org-helm-lisp-completion-info {
  color: red;
}

.org-helm-lisp-show-completion {
  background-color: #2f4f4f;
}

.org-helm-locate-finish {
  color: #0f0;
}

.org-helm-m-x-key {
  color: orange;
  text-decoration: underline;
}

.org-helm-match, .org-helm-match-item {
  color: #268bd2;
  background-color: #262626;
}

.org-helm-minibuffer-prompt {
  color: #268bd2;
  font-weight: bold;
}

.org-helm-moccur-buffer {
  color: #8787d7;
  background-color: #262626;
}

.org-helm-non-file-buffer {
  text-decoration: underline;
}

.org-helm-prefarg {
  color: #0f0;
}

.org-helm-resume-need-update {
  background-color: red;
}

.org-helm-selection {
  background-color: #444;
}

.org-helm-selection-line {
  background-color: #1c1c1c;
}

.org-helm-separator {
  color: #d75fd7;
  background-color: #262626;
}

.org-helm-source-header {
  color: #262626;
  background-color: #d75fd7;
  font-weight: bold;
}

.org-helm-visible-mark {
  color: #268bd2;
  background-color: #121212;
}

.org-help-argument-name {
  text-decoration: underline;
}

.org-highlight {
  color: #b2b2b2;
  background-color: #444;
}

.org-highlight-numbers-number {
  color: #d75fd7;
}

.org-hl-line {
  background-color: #1c1c1c;
}

.org-hl-todo {
  color: #cc9393;
  font-weight: bold;
}

.org-holiday {
  background-color: #8b4513;
}

.org-homoglyph {
  color: #0ff;
}

.org-hydra-face-amaranth {
  color: #e52b50;
  font-weight: bold;
}

.org-hydra-face-blue {
  color: #268bd2;
}

.org-hydra-face-pink {
  color: #ff6eb4;
  font-weight: bold;
}

.org-hydra-face-red {
  color: #d70000;
}

.org-hydra-face-teal {
  color: #367588;
  font-weight: bold;
}

.org-ibuffer-locked-buffer {
  color: #bc8f8f;
}

.org-ido-first-match {
  color: #d75fd7;
  font-weight: bold;
}

.org-ido-incomplete-regexp {
  color: #dc752f;
  background-color: #262626;
}

.org-ido-indicator {
  color: #ff0;
  background-color: red;
}

.org-ido-only-match {
  color: #86dc2f;
  font-weight: bold;
}

.org-ido-subdir {
  color: #268bd2;
}

.org-ido-vertical-first-match {
  color: #d75fd7;
  font-weight: bold;
}

.org-ido-vertical-match {
  color: #d75fd7;
}

.org-ido-vertical-only-match {
  color: #86dc2f;
  font-weight: bold;
}

.org-ido-virtual {
  color: #268bd2;
}

.org-imenu-list-entry-face-0 {
  color: gold;
}

.org-imenu-list-entry-face-1 {
  color: #90ee90;
}

.org-imenu-list-entry-face-2 {
  color: #add8e6;
}

.org-imenu-list-entry-face-3 {
  color: #f4a460;
}

.org-imenu-list-entry-subalist-face-0 {
  color: gold;
  font-weight: bold;
  text-decoration: underline;
}

.org-imenu-list-entry-subalist-face-1 {
  color: #90ee90;
  font-weight: bold;
  text-decoration: underline;
}

.org-imenu-list-entry-subalist-face-2 {
  color: #add8e6;
  font-weight: bold;
  text-decoration: underline;
}

.org-imenu-list-entry-subalist-face-3 {
  color: #f4a460;
  font-weight: bold;
  text-decoration: underline;
}

.org-info-command-ref-item {
  color: #7474ff 7474;
  background-color: #696969;
}

.org-info-constant-ref-item {
  color: #ff1493;
  background-color: #696969;
}

.org-info-double-quoted-name {
  color: #0ff;
}

.org-info-emphasis {
  text-decoration: underline;
}

.org-info-file {
  color: #ff0;
  background-color: #696969;
}

.org-info-function-ref-item {
  color: #4d4ddddd DDDD;
  background-color: #696969;
}

.org-info-header-node {
  color: #d75fd7;
  font-weight: bold;
}

.org-info-header-xref {
  color: #d75fd7;
  text-decoration: underline;
}

.org-info-index-match {
  color: #86dc2f;
  background-color: #444;
}

.org-info-macro-ref-item {
  color: #ff0;
  background-color: #696969;
}

.org-info-menu {
  color: #86dc2f;
}

.org-info-menu-header {
  font-weight: bold;
  text-decoration: underline;
}

.org-info-menu-star {
  color: red;
}

.org-info-node {
  color: #d75fd7;
  font-weight: bold;
}

.org-info-quoted-name {
  color: #268bd2;
}

.org-info-reference-item {
  font-weight: bold;
  text-decoration: underline;
}

.org-info-single-quote {
  color: #0f0;
  font-weight: bold;
}

.org-info-special-form-ref-item {
  color: #ff0;
  background-color: #696969;
}

.org-info-string {
  color: #2aa198;
}

.org-info-syntax-class-item {
  color: #FFFF9B9BFFFF;
  background-color: #696969;
}

.org-info-title-1 {
  font-size: 140%;
  font-weight: bold;
}

.org-info-title-2 {
  font-size: 130%;
  font-weight: bold;
}

.org-info-title-3 {
  font-size: 130%;
}

.org-info-title-4 {
  font-size: 120%;
}

.org-info-user-option-ref-item {
  color: red;
  background-color: #696969;
}

.org-info-variable-ref-item {
  color: orange;
  background-color: #696969;
}

.org-info-xref {
  color: #008787;
  text-decoration: underline;
}

.org-isearch {
  color: #262626;
  background-color: #86dc2f;
}

.org-isearch-fail {
  background-color: #8b0000;
}

.org-italic {
  text-decoration: underline;
}

.org-json-mode-object-name {
  color: #8787d7;
}

.org-keyword {
  color: #268bd2;
  font-weight: bold;
}

.org-lazy-highlight {
  background-color: #262626;
}

.org-line-number {
  color: #444;
  background-color: #1c1c1c;
}

.org-line-number-current-line {
  color: #b2b2b2;
  background-color: #1c1c1c;
}

.org-link {
  color: #008787;
  text-decoration: underline;
}

.org-link-visited {
  color: #d75fd7;
  text-decoration: underline;
}

.org-log-edit-header {
  color: #268bd2;
  font-weight: bold;
}

.org-log-edit-summary {
  color: #d75fd7;
  font-weight: bold;
}

.org-log-edit-unknown-header {
  color: #008787;
  background-color: #262626;
}

.org-lv-separator {
  background-color: #4d4d4d;
}

.org-magit-bisect-bad {
  color: #8b3a3a;
}

.org-magit-bisect-good {
  color: #556b2f;
}

.org-magit-bisect-skip {
  color: #b8860b;
}

.org-magit-blame-date {
  color: #67b11d;
  background-color: #262626;
}

.org-magit-blame-dimmed {
  color: #7f7f7f;
}

.org-magit-blame-hash {
  color: #d75fd7;
  background-color: #262626;
}

.org-magit-blame-heading {
  color: #67b11d;
  background-color: #262626;
}

.org-magit-blame-highlight, .org-magit-blame-margin {
  color: #fff;
  background-color: #404040;
}

.org-magit-blame-name, .org-magit-blame-summary {
  color: #875f00;
  background-color: #262626;
}

.org-magit-branch-current, .org-magit-branch-local {
  color: #268bd2;
  background-color: #262626;
  font-weight: bold;
}

.org-magit-branch-remote, .org-magit-branch-remote-head {
  color: #2aa198;
  background-color: #262626;
  font-weight: bold;
}

.org-magit-branch-upstream {
  font-style: italic;
}

.org-magit-cherry-equivalent {
  color: #f0f;
}

.org-magit-cherry-unmatched {
  color: #0ff;
}

.org-magit-diff-added {
  color: #dfd;
  background-color: #353;
}

.org-magit-diff-added-highlight {
  color: #cec;
  background-color: #363;
}

.org-magit-diff-base {
  color: #ffc;
  background-color: #552;
}

.org-magit-diff-base-highlight {
  color: #eeb;
  background-color: #662;
}

.org-magit-diff-conflict-heading {
  color: #888;
  background-color: #444;
}

.org-magit-diff-context {
  color: #b3b3b3;
}

.org-magit-diff-context-highlight {
  color: #b2b2b2;
  background-color: #1c1c1c;
}

.org-magit-diff-file-heading {
  font-weight: bold;
}

.org-magit-diff-file-heading-selection {
  color: #cd8162;
  background-color: #1c1c1c;
}

.org-magit-diff-hunk-heading {
  color: #888;
  background-color: #444;
}

.org-magit-diff-hunk-heading-highlight {
  color: #b2b2b2;
  background-color: #333;
}

.org-magit-diff-hunk-heading-selection {
  color: #cd8162;
  background-color: #333;
}

.org-magit-diff-hunk-region {
  font-weight: bold;
}

.org-magit-diff-lines-boundary, .org-magit-diff-lines-heading {
  color: #ccc;
  background-color: #8b4c39;
}

.org-magit-diff-our {
  color: #fdd;
  background-color: #533;
}

.org-magit-diff-our-highlight {
  color: #ecc;
  background-color: #633;
}

.org-magit-diff-removed {
  color: #fdd;
  background-color: #533;
}

.org-magit-diff-removed-highlight {
  color: #ecc;
  background-color: #633;
}

.org-magit-diff-their {
  color: #dfd;
  background-color: #353;
}

.org-magit-diff-their-highlight {
  color: #cec;
  background-color: #363;
}

.org-magit-diff-whitespace-warning {
  background-color: #008787;
}

.org-magit-diffstat-added {
  color: #484;
}

.org-magit-diffstat-removed {
  color: #a44;
}

.org-magit-dimmed {
  color: #7f7f7f;
}

.org-magit-hash {
  color: #8787d7;
}

.org-magit-head {
  color: #268bd2;
  background-color: #262626;
  font-weight: bold;
}

.org-magit-header-line {
  color: #268bd2;
  font-weight: bold;
}

.org-magit-header-line-key {
  color: #268bd2;
}

.org-magit-header-line-log-select {
  font-weight: bold;
}

.org-magit-keyword {
  color: #2aa198;
}

.org-magit-keyword-squash {
  color: #dc752f;
  background-color: #262626;
}

.org-magit-log-author {
  color: #d75fd7;
}

.org-magit-log-date, .org-magit-log-graph {
  color: #ccc;
}

.org-magit-mode-line-process {
  font-weight: bold;
}

.org-magit-mode-line-process-error {
  color: #e0211d;
}

.org-magit-popup-argument {
  color: #dc752f;
  background-color: #262626;
}

.org-magit-popup-disabled-argument {
  color: #585858;
}

.org-magit-popup-heading {
  color: #268bd2;
  font-weight: bold;
}

.org-magit-popup-key {
  color: #268bd2;
}

.org-magit-popup-option-value {
  color: #2aa198;
}

.org-magit-process-ng {
  color: #dc752f;
  font-weight: bold;
}

.org-magit-process-ok {
  color: #d75fd7;
  font-weight: bold;
}

.org-magit-reflog-amend {
  color: #af00df;
}

.org-magit-reflog-checkout {
  color: #268bd2;
}

.org-magit-reflog-cherry-pick, .org-magit-reflog-commit, .org-magit-reflog-merge {
  color: #67b11d;
}

.org-magit-reflog-other {
  color: #0ff;
}

.org-magit-reflog-rebase {
  color: #af00df;
}

.org-magit-reflog-remote {
  color: #0ff;
}

.org-magit-reflog-reset {
  color: #d70000;
}

.org-magit-refname, .org-magit-refname-pullreq, .org-magit-refname-stash, .org-magit-refname-wip {
  color: #ccc;
}

.org-magit-section-heading {
  color: #268bd2;
  font-weight: bold;
}

.org-magit-section-heading-selection {
  color: #cd8162;
}

.org-magit-section-highlight {
  background-color: #1c1c1c;
}

.org-magit-section-secondary-heading {
  font-weight: bold;
}

.org-magit-sequence-done {
  color: #8787d7;
}

.org-magit-sequence-drop {
  color: #cd5c5c;
}

.org-magit-sequence-exec {
  color: #8787d7;
}

.org-magit-sequence-head {
  color: #b0e2ff;
}

.org-magit-sequence-onto {
  color: #8787d7;
}

.org-magit-sequence-part {
  color: #eedc82;
}

.org-magit-sequence-pick {
  color: #b2b2b2;
  background-color: #262626;
}

.org-magit-sequence-stop {
  color: #b4eeb4;
}

.org-magit-signature-bad {
  color: red;
  font-weight: bold;
}

.org-magit-signature-error {
  color: #cd2626;
}

.org-magit-signature-expired, .org-magit-signature-expired-key {
  color: orange;
}

.org-magit-signature-good {
  color: #0f0;
}

.org-magit-signature-revoked {
  color: #d02090;
}

.org-magit-signature-untrusted {
  color: #0ff;
}

.org-magit-tag {
  color: #eedc82;
}

.org-match {
  color: #86dc2f;
  background-color: #444;
}

.org-message-cited-text {
  color: #ffaeb9;
}

.org-message-header-cc {
  color: #7fff00;
  font-weight: bold;
}

.org-message-header-name {
  color: #0f0;
}

.org-message-header-newsgroups {
  color: #ff0;
  font-style: italic;
  font-weight: bold;
}

.org-message-header-other {
  color: #ff3e96;
}

.org-message-header-subject {
  color: #c0ff3e;
}

.org-message-header-to {
  color: #caff70;
  font-weight: bold;
}

.org-message-header-xheader {
  color: #00bfff;
}

.org-message-mml {
  color: #00fa9a;
}

.org-message-separator {
  color: #b0e2ff;
}

.org-minibuffer-prompt {
  color: #268bd2;
  font-weight: bold;
}

.org-mm-command-output {
  color: #228b22;
}

.org-mode-line {
  color: #b2b2b2;
  background-color: #121212;
}

.org-mode-line-buffer-id, .org-mode-line-buffer-id-inactive {
  color: #d75fd7;
  font-weight: bold;
}

.org-mode-line-emphasis {
  font-weight: bold;
}

.org-mode-line-inactive {
  color: #b2b2b2;
  background-color: #262626;
}

.org-mouse-drag-and-drop-region {
  background-color: #444;
}

.org-negation-char {
  color: #d75fd7;
}

.org-next-error {
  background-color: #444;
}

.org-nobreak-hyphen {
  color: #0ff;
}

.org-nobreak-space {
  color: #0ff;
  text-decoration: underline;
}

.org-ns-working-text {
  text-decoration: underline;
}

.org-org-agenda-calendar-event, .org-org-agenda-calendar-sexp {
  color: #b2b2b2;
  background-color: #262626;
}

.org-org-agenda-clocking {
  color: #d75fd7;
  background-color: #444;
}

.org-org-agenda-column-dateline {
  background-color: #444;
}

.org-org-agenda-current-time {
  color: #2aa198;
}

.org-org-agenda-date {
  color: #8787d7;
}

.org-org-agenda-date-today {
  color: #268bd2;
  font-weight: bold;
}

.org-org-agenda-date-weekend {
  color: #8787d7;
  font-weight: bold;
}

.org-org-agenda-diary {
  color: #b2b2b2;
  background-color: #262626;
}

.org-org-agenda-dimmed-todo {
  color: #7f7f7f;
}

.org-org-agenda-done {
  color: #86dc2f;
}

.org-org-agenda-filter-category, .org-org-agenda-filter-effort, .org-org-agenda-filter-regexp, .org-org-agenda-filter-tags {
  color: #b2b2b2;
  background-color: #121212;
}

.org-org-agenda-restriction-lock {
  background-color: #1c1c1c;
}

.org-org-agenda-structure {
  color: #d75fd7;
  font-weight: bold;
}

.org-org-archived {
  color: #585858;
}

.org-org-block {
  color: #b2b2b2;
  background-color: #262626;
}

.org-org-block-begin-line, .org-org-block-end-line {
  color: #af5faf;
  background-color: #333;
}

.org-org-checkbox {
  font-weight: bold;
}

.org-org-checkbox-statistics-done {
  color: #86dc2f;
  background-color: #262626;
  font-weight: bold;
}

.org-org-checkbox-statistics-todo {
  color: #dc752f;
  background-color: #262626;
  font-weight: bold;
}

.org-org-clock-overlay {
  color: #d75fd7;
}

.org-org-code {
  color: #0ff;
}

.org-org-column, .org-org-column-title {
  background-color: #444;
}

.org-org-date {
  color: #8787d7;
  text-decoration: underline;
}

.org-org-date-selected {
  color: #262626;
  background-color: #d75fd7;
}

.org-org-default {
  color: #b2b2b2;
  background-color: #262626;
}

.org-org-document-info {
  color: #afeeee;
}

.org-org-document-info-keyword {
  color: #af875f;
}

.org-org-document-title {
  color: #d75fd7;
  font-size: 140%;
  font-weight: bold;
  text-decoration: underline;
}

.org-org-done {
  color: #86dc2f;
  background-color: #262626;
  font-weight: bold;
}

.org-org-drawer {
  color: #87cefa;
}

.org-org-ellipsis {
  color: #268bd2;
}

.org-org-footnote {
  color: #b2b2b2;
  text-decoration: underline;
}

.org-org-formula {
  color: #ff7f24;
}

.org-org-habit-alert {
  background-color: gold;
}

.org-org-habit-alert-future {
  background-color: #b8860b;
}

.org-org-habit-clear {
  background-color: #00f;
}

.org-org-habit-clear-future {
  background-color: #191970;
}

.org-org-habit-overdue {
  background-color: #b22222;
}

.org-org-habit-overdue-future {
  background-color: #8b0000;
}

.org-org-habit-ready {
  background-color: #228b22;
}

.org-org-habit-ready-future {
  background-color: #006400;
}

.org-org-headline-done {
  color: #ffa07a;
}

.org-org-hide, .org-org-indent {
  color: #262626;
}

.org-org-kbd {
  color: #b2b2b2;
  background-color: #444;
}

.org-org-latex-and-related {
  color: #deb887;
}

.org-org-level-1 {
  color: #268bd2;
  font-size: 130%;
  font-weight: bold;
}

.org-org-level-2 {
  color: #2aa198;
  font-size: 120%;
  font-weight: bold;
}

.org-org-level-3 {
  color: #67b11d;
  font-size: 110%;
}

.org-org-level-4 {
  color: #875f00;
}

.org-org-level-5 {
  color: #268bd2;
}

.org-org-level-6 {
  color: #2aa198;
}

.org-org-level-7 {
  color: #67b11d;
}

.org-org-level-8 {
  color: #875f00;
}

.org-org-link {
  color: #008787;
  text-decoration: underline;
}

.org-org-list-dt {
  font-weight: bold;
}

.org-org-macro {
  color: #deb887;
}

.org-org-meta-line {
  color: #af875f;
}

.org-org-mode-line-clock {
  color: #b2b2b2;
  background-color: #121212;
}

.org-org-mode-line-clock-overrun {
  color: #e0211d;
}

.org-org-priority {
  color: #dc752f;
  font-weight: bold;
}

.org-org-quote {
  color: #b2b2b2;
  background-color: #262626;
  font-style: italic;
}

.org-org-scheduled {
  color: #d75fd7;
}

.org-org-scheduled-previously {
  color: #b2b2b2;
  font-style: italic;
}

.org-org-scheduled-today {
  color: #d75fd7;
}

.org-org-sexp-date {
  color: #b2b2b2;
}

.org-org-special-keyword {
  color: #d75fd7;
}

.org-org-super-agenda-header {
  color: #d75fd7;
  font-weight: bold;
}

.org-org-table {
  color: #b2b2b2;
  background-color: #262626;
}

.org-org-tag, .org-org-tag-group {
  color: #af875f;
}

.org-org-target {
  text-decoration: underline;
}

.org-org-time-grid {
  color: #2aa198;
}

.org-org-todo {
  color: #dc752f;
  background-color: #262626;
  font-weight: bold;
}

.org-org-upcoming-deadline {
  color: #dc752f;
  font-weight: bold;
}

.org-org-upcoming-distant-deadline {
  color: #86dc2f;
  font-weight: bold;
}

.org-org-verbatim {
  color: #268bd2;
}

.org-org-verse {
  color: #b2b2b2;
  background-color: #262626;
  font-style: italic;
}

.org-org-warning {
  color: #e0211d;
  font-weight: bold;
}

.org-outline-1 {
  color: #268bd2;
  font-size: 130%;
  font-weight: bold;
}

.org-outline-2 {
  color: #2aa198;
  font-size: 120%;
  font-weight: bold;
}

.org-outline-3 {
  color: #67b11d;
  font-size: 110%;
}

.org-outline-4 {
  color: #875f00;
}

.org-outline-5 {
  color: #268bd2;
}

.org-outline-6 {
  color: #2aa198;
}

.org-outline-7 {
  color: #67b11d;
}

.org-outline-8 {
  color: #875f00;
}

.org-package-description {
  color: #b2b2b2;
  background-color: #262626;
}

.org-package-name {
  color: #008787;
  text-decoration: underline;
}

.org-package-status-avail-obso {
  color: #e0211d;
}

.org-package-status-available {
  color: #b2b2b2;
  background-color: #262626;
}

.org-package-status-built-in {
  color: #268bd2;
}

.org-package-status-dependency {
  color: #008787;
  background-color: #262626;
}

.org-package-status-disabled {
  color: #dc752f;
  background-color: #262626;
}

.org-package-status-external {
  color: #268bd2;
}

.org-package-status-held {
  color: #d75fd7;
}

.org-package-status-incompat {
  color: #e0211d;
}

.org-package-status-installed {
  color: #008787;
  background-color: #262626;
}

.org-package-status-unsigned {
  color: #dc752f;
  background-color: #262626;
}

.org-page-break-lines {
  color: #444;
}

.org-persp-face-lighter-buffer-not-in-persp {
  color: #00f;
  background-color: red;
  font-weight: bold;
}

.org-persp-face-lighter-default {
  text-decoration: underline;
}

.org-persp-face-lighter-nil-persp {
  font-style: italic;
  font-weight: bold;
}

.org-php-Xthis, .org-php-Xthis-sigil {
  color: #d75fd7;
}

.org-php-builtin {
  color: #268bd2;
}

.org-php-constant {
  color: #d75fd7;
}

.org-php-constant-assign, .org-php-doc-Xthis, .org-php-doc-Xthis-sigil {
  color: #df005f;
  font-weight: bold;
}

.org-php-doc-annotation-tag {
  color: #d75fd7;
}

.org-php-doc-class-name {
  color: #2aa198;
}

.org-php-doc-variable-sigil {
  color: #8787d7;
}

.org-php-errorcontrol-op {
  color: #df005f;
  font-weight: bold;
}

.org-php-extras-autocomplete-candidate, .org-php-extras-autocomplete-selection, .org-php-function-call {
  color: #b2b2b2;
  background-color: #262626;
}

.org-php-function-name {
  color: #d75fd7;
  font-weight: bold;
}

.org-php-keyword {
  color: #268bd2;
  font-weight: bold;
}

.org-php-magical-constant {
  color: #268bd2;
}

.org-php-method-call, .org-php-object-op, .org-php-paamayim-nekudotayim {
  color: #b2b2b2;
  background-color: #262626;
}

.org-php-php-tag {
  color: #d75fd7;
}

.org-php-property-name {
  color: #8787d7;
}

.org-php-static-method-call {
  color: #b2b2b2;
  background-color: #262626;
}

.org-php-string {
  color: #2aa198;
}

.org-php-type {
  color: #df005f;
  font-weight: bold;
}

.org-php-variable-name {
  color: #8787d7;
}

.org-php-variable-sigil {
  color: #b2b2b2;
  background-color: #262626;
}

.org-powerline-active0 {
  color: #b2b2b2;
  background-color: #121212;
}

.org-powerline-active1, .org-powerline-active2 {
  color: #b2b2b2;
  background-color: #444;
}

.org-powerline-inactive0 {
  color: #b2b2b2;
  background-color: #262626;
}

.org-powerline-inactive1, .org-powerline-inactive2 {
  color: #b2b2b2;
  background-color: #1c1c1c;
}

.org-preprocessor {
  color: #d75fd7;
}

.org-query-replace {
  color: #262626;
  background-color: #86dc2f;
}

.org-rainbow-delimiters-depth-1 {
  color: #268bd2;
}

.org-rainbow-delimiters-depth-2 {
  color: #d75fd7;
}

.org-rainbow-delimiters-depth-3 {
  color: #2aa198;
}

.org-rainbow-delimiters-depth-4 {
  color: #67b11d;
}

.org-rainbow-delimiters-depth-5 {
  color: #875f00;
}

.org-rainbow-delimiters-depth-6 {
  color: #268bd2;
}

.org-rainbow-delimiters-depth-7 {
  color: #d75fd7;
}

.org-rainbow-delimiters-depth-8 {
  color: #2aa198;
}

.org-rainbow-delimiters-depth-9 {
  color: #9cb6ad;
}

.org-rainbow-delimiters-mismatched, .org-rainbow-delimiters-unmatched {
  color: #e0211d;
  text-decoration: overline;
}

.org-rcirc-bright-nick {
  color: #0ff;
  background-color: #262626;
}

.org-rcirc-dim-nick {
  color: #585858;
}

.org-rcirc-keyword {
  color: #67b11d;
  background-color: #262626;
}

.org-rcirc-my-nick {
  color: #87cefa;
}

.org-rcirc-nick-in-message {
  color: #0ff;
}

.org-rcirc-nick-in-message-full-line {
  font-weight: bold;
}

.org-rcirc-other-nick {
  color: #eedd82;
}

.org-rcirc-prompt {
  color: #0ff;
}

.org-rcirc-server, .org-rcirc-server-prefix {
  color: #ff7f24;
}

.org-rcirc-timestamp {
  color: #268bd2;
}

.org-rcirc-track-keyword {
  color: #262626;
  background-color: #67b11d;
}

.org-rcirc-track-nick {
  color: #b2b2b2;
  background-color: #262626;
}

.org-rcirc-url {
  color: #008787;
  text-decoration: underline;
}

.org-read-multiple-choice {
  font-weight: bold;
  text-decoration: underline;
}

.org-reb-match-0 {
  background-color: #36648b;
}

.org-reb-match-1 {
  background-color: #0000cd;
}

.org-reb-match-2 {
  background-color: #458b00;
}

.org-reb-match-3 {
  background-color: #8b4726;
}

.org-rectangle-preview {
  background-color: #444;
}

.org-regexp-grouping-backslash, .org-regexp-grouping-construct {
  font-weight: bold;
}

.org-region {
  background-color: #444;
}

.org-rmail-header-name {
  color: #d75fd7;
  font-weight: bold;
}

.org-rmail-highlight {
  color: #b2b2b2;
  background-color: #444;
}

.org-secondary-selection {
  background-color: #121212;
}

.org-sgml-namespace {
  color: #268bd2;
}

.org-sh-escaped-newline {
  color: #2aa198;
}

.org-sh-heredoc {
  color: #ff0;
  font-weight: bold;
}

.org-sh-quoted-exec {
  color: salmon;
}

.org-shadow {
  color: #585858;
}

.org-show-paren-match {
  color: #86dc2f;
  font-weight: bold;
  text-decoration: underline;
}

.org-show-paren-match-expression {
  background-color: #262626;
}

.org-show-paren-mismatch {
  color: #e0211d;
  font-weight: bold;
  text-decoration: underline;
}

.org-shr-link {
  color: #008787;
  text-decoration: underline;
}

.org-shr-strike-through {
  text-decoration: line-through;
}

.org-smerge-base, .org-smerge-lower {
  background-color: #262626;
}

.org-smerge-markers {
  color: #888;
  background-color: #444;
}

.org-smerge-refined-added {
  color: #67b11d;
  background-color: #262626;
}

.org-smerge-refined-changed {
  color: #268bd2;
  background-color: #262626;
}

.org-smerge-refined-removed {
  color: #d70000;
  background-color: #262626;
}

.org-smerge-upper {
  background-color: #262626;
}

.org-sp-pair-overlay {
  background-color: #444;
}

.org-sp-show-pair-enclosing {
  color: #b2b2b2;
  background-color: #444;
}

.org-sp-show-pair-match {
  color: #86dc2f;
  font-weight: bold;
  text-decoration: underline;
}

.org-sp-show-pair-mismatch {
  color: #e0211d;
  font-weight: bold;
  text-decoration: underline;
}

.org-sp-wrap-overlay {
  background-color: #444;
}

.org-sp-wrap-overlay-closing-pair {
  color: red;
  background-color: #444;
}

.org-sp-wrap-overlay-opening-pair {
  color: #0f0;
  background-color: #444;
}

.org-sp-wrap-tag-overlay {
  background-color: #444;
}

.org-spaceline-flycheck-error {
  color: #e0211d;
}

.org-spaceline-flycheck-info {
  color: #268bd2;
}

.org-spaceline-flycheck-warning {
  color: #dc752f;
}

.org-spaceline-python-venv {
  color: #d75fd7;
}

.org-spacemacs-helm-navigation-ts {
  color: #000;
}

.org-spacemacs-ido-navigation-ts {
  color: #000;
  font-weight: bold;
}

.org-spacemacs-micro-state-binding {
  font-weight: bold;
}

.org-spacemacs-micro-state-header {
  color: #000;
  background-color: #eead0e;
  font-weight: bold;
}

.org-spacemacs-transient-state-title {
  color: #d75fd7;
  font-weight: bold;
}

.org-speedbar-button {
  color: #00cd00;
}

.org-speedbar-directory {
  color: #add8e6;
}

.org-speedbar-file {
  color: #0ff;
}

.org-speedbar-highlight {
  background-color: #2e8b57;
}

.org-speedbar-selected {
  color: red;
  text-decoration: underline;
}

.org-speedbar-separator {
  color: #fff;
  background-color: #00f;
  text-decoration: overline;
}

.org-speedbar-tag {
  color: #ff0;
}

.org-string {
  color: #2aa198;
}

.org-success {
  color: #86dc2f;
}

.org-symbol-overlay-default {
  color: #b2b2b2;
  background-color: #444;
}

.org-symbol-overlay-face-1 {
  color: #000;
  background-color: #1e90ff;
}

.org-symbol-overlay-face-2 {
  color: #000;
  background-color: #ff69b4;
}

.org-symbol-overlay-face-3 {
  color: #000;
  background-color: #ff0;
}

.org-symbol-overlay-face-4 {
  color: #000;
  background-color: orchid;
}

.org-symbol-overlay-face-5 {
  color: #000;
  background-color: red;
}

.org-symbol-overlay-face-6 {
  color: #000;
  background-color: salmon;
}

.org-symbol-overlay-face-7 {
  color: #000;
  background-color: #00ff7f;
}

.org-symbol-overlay-face-8 {
  color: #000;
  background-color: #40e0d0;
}

.org-table-cell {
  color: #e5e5e5;
  background-color: #00f;
}

.org-term {
  color: #b2b2b2;
  background-color: #262626;
}

.org-term-bold {
  font-weight: bold;
}

.org-term-color-black {
  color: #080808;
}

.org-term-color-blue {
  color: #268bd2;
}

.org-term-color-cyan {
  color: #0ff;
}

.org-term-color-green {
  color: #67b11d;
}

.org-term-color-magenta {
  color: #af00df;
}

.org-term-color-red {
  color: #d70000;
}

.org-term-color-white {
  color: #b2b2b2;
}

.org-term-color-yellow {
  color: #875f00;
}

.org-term-underline {
  text-decoration: underline;
}

.org-tex-math {
  color: #2aa198;
}

.org-tide-choice {
  color: #dc752f;
  background-color: #262626;
}

.org-tide-file {
  color: #d75fd7;
  font-weight: bold;
}

.org-tide-hl-identifier {
  color: #875f00;
  background-color: #262626;
}

.org-tide-imenu-type {
  color: #df005f;
  font-weight: bold;
}

.org-tide-line-number {
  color: #268bd2;
  font-weight: bold;
}

.org-tide-match {
  color: #86dc2f;
  background-color: #444;
}

.org-tool-bar {
  color: #000;
}

.org-tooltip {
  color: #b2b2b2;
  background-color: #333;
}

.org-trailing-whitespace {
  background-color: #008787;
}

.org-transient-active-infix {
  background-color: #121212;
}

.org-transient-argument {
  color: #dc752f;
  background-color: #262626;
}

.org-transient-disabled-suffix {
  color: #000;
  background-color: red;
  font-weight: bold;
}

.org-transient-enabled-suffix {
  color: #000;
  background-color: #0f0;
  font-weight: bold;
}

.org-transient-heading {
  color: #268bd2;
  font-weight: bold;
}

.org-transient-inactive-argument, .org-transient-inactive-value {
  color: #585858;
}

.org-transient-key {
  color: #268bd2;
}

.org-transient-mismatched-key, .org-transient-nonstandard-key {
  text-decoration: underline;
}

.org-transient-separator {
  background-color: #4d4d4d;
}

.org-transient-unreachable, .org-transient-unreachable-key {
  color: #585858;
}

.org-transient-value {
  color: #2aa198;
}

.org-tty-menu-disabled {
  color: #d3d3d3;
  background-color: #00f;
}

.org-tty-menu-enabled {
  color: #ff0;
  background-color: #00f;
  font-weight: bold;
}

.org-tty-menu-selected {
  background-color: red;
}

.org-type {
  color: #df005f;
  font-weight: bold;
}

.org-typescript-jsdoc-tag {
  color: #708090;
}

.org-typescript-jsdoc-type {
  color: #4682b4;
}

.org-typescript-jsdoc-value {
  color: #8b7500;
}

.org-underline {
  text-decoration: underline;
}

.org-undo-tree-visualizer-active-branch {
  color: #fff;
  font-weight: bold;
}

.org-undo-tree-visualizer-current {
  color: #268bd2;
}

.org-undo-tree-visualizer-default {
  color: #b2b2b2;
}

.org-undo-tree-visualizer-register {
  color: #d75fd7;
}

.org-undo-tree-visualizer-unmodified, .org-variable-name {
  color: #8787d7;
}

.org-vertical-border {
  color: #111;
}

.org-warning {
  color: #dc752f;
}

.org-warning-1 {
  color: #dc752f;
  background-color: #262626;
}

.org-web-mode-annotation {
  color: #008787;
  background-color: #262626;
}

.org-web-mode-annotation-html {
  color: #008787;
  background-color: #262626;
  font-style: italic;
}

.org-web-mode-annotation-tag {
  color: #008787;
  background-color: #262626;
  text-decoration: underline;
}

.org-web-mode-annotation-type {
  color: #008787;
  background-color: #262626;
  font-weight: bold;
}

.org-web-mode-annotation-value {
  color: #008787;
  background-color: #262626;
  font-style: italic;
}

.org-web-mode-block {
  background-color: #000;
}

.org-web-mode-block-attr-name {
  color: #8fbc8f;
}

.org-web-mode-block-attr-value {
  color: #5f9ea0;
}

.org-web-mode-block-comment {
  color: #008787;
  background-color: #262626;
}

.org-web-mode-block-control, .org-web-mode-block-delimiter {
  color: #d75fd7;
}

.org-web-mode-block-string {
  color: #2aa198;
}

.org-web-mode-bold {
  font-weight: bold;
}

.org-web-mode-builtin {
  color: #268bd2;
}

.org-web-mode-comment {
  color: #008787;
  background-color: #262626;
}

.org-web-mode-comment-keyword {
  font-weight: bold;
}

.org-web-mode-constant, .org-web-mode-css-at-rule {
  color: #d75fd7;
}

.org-web-mode-css-color {
  color: #268bd2;
}

.org-web-mode-css-comment {
  color: #008787;
  background-color: #262626;
}

.org-web-mode-css-function, .org-web-mode-css-priority {
  color: #268bd2;
}

.org-web-mode-css-property-name {
  color: #8787d7;
}

.org-web-mode-css-pseudo-class {
  color: #268bd2;
}

.org-web-mode-css-selector {
  color: #268bd2;
  font-weight: bold;
}

.org-web-mode-css-string {
  color: #2aa198;
}

.org-web-mode-css-variable {
  color: #8787d7;
  font-style: italic;
}

.org-web-mode-current-column-highlight {
  background-color: #3e3c36;
}

.org-web-mode-current-element-highlight {
  background-color: #121212;
}

.org-web-mode-doctype {
  color: #008787;
  background-color: #262626;
}

.org-web-mode-error {
  background-color: red;
}

.org-web-mode-filter {
  color: #d75fd7;
  font-weight: bold;
}

.org-web-mode-folded {
  text-decoration: underline;
}

.org-web-mode-function-call, .org-web-mode-function-name {
  color: #d75fd7;
  font-weight: bold;
}

.org-web-mode-html-attr-custom, .org-web-mode-html-attr-engine, .org-web-mode-html-attr-equal, .org-web-mode-html-attr-name {
  color: #d75fd7;
}

.org-web-mode-html-attr-value {
  color: #268bd2;
}

.org-web-mode-html-entity {
  font-style: italic;
}

.org-web-mode-html-tag {
  color: #268bd2;
}

.org-web-mode-html-tag-bracket {
  color: #cdc9c9;
}

.org-web-mode-html-tag-custom {
  color: #268bd2;
}

.org-web-mode-html-tag-namespaced {
  color: #d75fd7;
}

.org-web-mode-html-tag-unclosed {
  color: #268bd2;
  text-decoration: underline;
}

.org-web-mode-inlay {
  background-color: #000;
}

.org-web-mode-italic {
  font-style: italic;
}

.org-web-mode-javascript-comment {
  color: #008787;
  background-color: #262626;
}

.org-web-mode-javascript-string {
  color: #2aa198;
}

.org-web-mode-json-comment {
  color: #008787;
  background-color: #262626;
}

.org-web-mode-json-context {
  color: #cd69c9;
}

.org-web-mode-json-key {
  color: plum;
}

.org-web-mode-json-string {
  color: #2aa198;
}

.org-web-mode-jsx-depth-1 {
  background-color: #000053;
}

.org-web-mode-jsx-depth-2 {
  background-color: #001970;
}

.org-web-mode-jsx-depth-3 {
  background-color: #002984;
}

.org-web-mode-jsx-depth-4 {
  background-color: #49599a;
}

.org-web-mode-jsx-depth-5 {
  background-color: #9499b7;
}

.org-web-mode-keyword {
  color: #268bd2;
}

.org-web-mode-param-name {
  color: #cdc9c9;
}

.org-web-mode-part {
  background-color: #000;
}

.org-web-mode-part-comment {
  color: #008787;
  background-color: #262626;
}

.org-web-mode-part-string {
  color: #2aa198;
}

.org-web-mode-preprocessor {
  color: #d75fd7;
}

.org-web-mode-script {
  background-color: #000;
}

.org-web-mode-sql-keyword {
  font-style: italic;
  font-weight: bold;
}

.org-web-mode-string {
  color: #2aa198;
}

.org-web-mode-style {
  background-color: #000;
}

.org-web-mode-symbol {
  color: #df005f;
}

.org-web-mode-type {
  color: #df005f;
  font-weight: bold;
}

.org-web-mode-underline {
  text-decoration: underline;
}

.org-web-mode-variable-name {
  color: #8787d7;
}

.org-web-mode-warning {
  color: #dc752f;
  background-color: #262626;
}

.org-web-mode-whitespace {
  background-color: #68228b;
}

.org-which-func {
  color: #d75fd7;
}

.org-which-key-command-description {
  color: #b2b2b2;
}

.org-which-key-docstring {
  color: #2aa198;
}

.org-which-key-group-description {
  color: #268bd2;
}

.org-which-key-highlighted-command {
  color: #b2b2b2;
  text-decoration: underline;
}

.org-which-key-key {
  color: #d75fd7;
  font-weight: bold;
}

.org-which-key-local-map-description {
  color: #b2b2b2;
}

.org-which-key-note, .org-which-key-separator {
  color: #2aa198;
}

.org-which-key-special-key {
  color: #262626;
  background-color: #d75fd7;
}

.org-whitespace-big-indent {
  color: #b22222;
  background-color: red;
}

.org-whitespace-empty {
  color: #875f00;
}

.org-whitespace-hspace {
  color: #a9a9a9;
  background-color: #3d3d3d;
}

.org-whitespace-indentation {
  color: #dc752f;
}

.org-whitespace-line, .org-whitespace-newline {
  color: #d75fd7;
}

.org-whitespace-space {
  color: #dc752f;
}

.org-whitespace-space-after-tab, .org-whitespace-space-before-tab {
  color: #875f00;
}

.org-whitespace-tab {
  color: #444;
}

.org-whitespace-trailing {
  color: #dc752f;
  background-color: #e0211d;
}

.org-widget-button {
  font-weight: bold;
}

.org-widget-button-pressed {
  color: red;
}

.org-widget-documentation {
  color: #32cd32;
}

.org-widget-field {
  color: #000;
  background-color: #cdcd00;
}

.org-widget-inactive {
  color: #585858;
}

.org-widget-single-line-field {
  color: #000;
  background-color: #00cd00;
}

.org-window-divider {
  color: #999;
}

.org-window-divider-first-pixel {
  color: #ccc;
}

.org-window-divider-last-pixel {
  color: #666;
}

a {
  color: inherit;
  background-color: inherit;
  font: inherit;
  text-decoration: inherit;
}

a:hover {
  text-decoration: underline;
}

.playlist {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.playlist .playlist-player {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 2em;
  display: flex;
}

.playlist .playlist-player img, .playlist .playlist-player iframe {
  max-width: 20em;
  width: 100%;
}

.playlist .playlist-player iframe {
  height: 80px;
}

.playlist ul {
  max-width: 20em;
  margin: 0;
  padding: 0;
  list-style: none;
}

.playlist ul li {
  align-items: center;
  margin-bottom: .625em;
  display: flex;
}

.playlist ul li img {
  margin-right: .625em;
}

.playlist ul li span {
  display: block;
}

.playlist ul li .album {
  display: none;
}

.playlist ul li .artist {
  font-size: .625em;
}

body {
  font-display: block;
  background: #fcfcf9;
  font-family: EB Garamond, serif;
  font-size: 3em;
  line-height: 1.25em;
}

@media (min-device-width: 40em) and (orientation: landscape) {
  body {
    font-size: 1.25em;
  }
}

.content {
  max-width: 62.5\65  m;
}

pre {
  overflow-x: scroll;
}

h1, h2, h3, h4, h5, h6 {
  color: #36ade2;
  font-family: EB Garamond, serif;
}

nav {
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 80em) {
  nav {
    flex-wrap: nowrap;
  }
}

nav h1 {
  font-size: 1.2em;
  line-height: 1em;
}

nav ul {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

nav ul li {
  margin: .5rem 2rem;
}

@media (min-width: 80em) {
  nav ul {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  nav ul li {
    margin: 0 2rem;
  }
}

#content > div:not(nav) {
  padding: 2em 0;
}

footer {
  text-align: center;
  font-size: .624em;
  line-height: 1.2;
}

/*# sourceMappingURL=index.dbd1ebda.css.map */
